import React, { useEffect } from "react";
import classes from "./RdvInformationStep.module.scss";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import I18n from "../../../config/i18n/I18n";
import Autocomplete from "@mui/lab/Autocomplete";
import { useForm } from "react-hook-form";
import { Alert, Stack } from "@mui/material";
import Patient from "../../../model/Patient.model";
import { phonePattern } from "../../../config/regExpPatterns";
import environment from "../../../config/environment";
import { Position } from "../../../model/Appointment.model";
import PlacesAutoComplete from "../../ui/placesAutoComplete/PlacesAutoComplete";
type rdvInformationStepPropsType = {
    position: (position: Position) => void;
    information: (arg1: string, arg2: any) => void;
    nextStep: VoidFunction;
    informationStateObject: Patient;
};

const RdvInformationStep: React.FC<rdvInformationStepPropsType> = (
    props: rdvInformationStepPropsType
): React.ReactElement => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Patient & Position>();
    /**
     * Function to be called after data validation
     * @param data
     */
    const onSubmit = (data: any) => {
        props.nextStep();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Stack spacing={1} direction="column">
                <PlacesAutoComplete
                    register={register}
                    errors={errors}
                    placeSelected={props.position}
                />

                <Autocomplete
                    options={["MR", "MRS"]}
                    getOptionLabel={(option) =>
                        I18n.getTranslation(option.toString())
                    }
                    onChange={(element, value: any) =>
                        props.information("civility", value)
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            {...register("civility", { required: true })}
                            label={I18n.civility}
                            variant="outlined"
                            error={errors.civility !== undefined}
                        />
                    )}
                />
                <TextField
                    error={errors.fullName !== undefined}
                    label={I18n.lastname + " & " + I18n.firstname}
                    variant="outlined"
                    {...register("fullName", { required: true })}
                    onChange={(event) =>
                        props.information("fullName", event.target.value)
                    }
                />
                <TextField
                    error={errors.email !== undefined}
                    label={I18n.email}
                    type="email"
                    variant="outlined"
                    {...register("email", {
                        required: true,
                        onChange: (event) =>
                            props.information("email", event.target.value),
                    })}
                />
                <TextField
                    error={errors.birthDate !== undefined}
                    id="date"
                    label={I18n.birthDate}
                    type="date"
                    variant="outlined"
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...register("birthDate", {
                        required: true,
                        onChange: (event) =>
                            props.information("birthDate", event.target.value),
                    })}
                />
                <TextField
                    error={errors.mobile !== undefined}
                    label={I18n.mobile}
                    placeholder={I18n.mobilePlaceholder}
                    variant="outlined"
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...register(
                        "mobile",
                        environment.production
                            ? {
                                  required: true,
                                  maxLength: 10,
                                  pattern: {
                                      value: phonePattern,
                                      message: "invalid phone number",
                                  },
                                  onChange: (event) =>
                                      props.information(
                                          "mobile",
                                          event.target.value
                                      ),
                              }
                            : {
                                  required: true,
                                  onChange: (event) =>
                                      props.information(
                                          "mobile",
                                          event.target.value
                                      ),
                              }
                    )}
                />
                <Button type="submit" variant="outlined" color="primary">
                    {I18n.next}
                </Button>
                {(errors.civility ||
                    errors.fullName ||
                    errors.email ||
                    errors.mobile) && (
                    <Alert severity="error">{I18n.requiredProperties}</Alert>
                )}
            </Stack>
        </form>
    );
};
export default RdvInformationStep;
