import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import Typography from "@mui/material/Typography/Typography";
import React from "react";
import I18n from "../../config/i18n/I18n";
import PageTitle from "../../features/ui/pageTitle/PageTitle";
import classes from "./Conditions.module.scss";
type Props = {};

const Conditions = (props: Props) => {
    return (
        <div className={classes.conditionsContainer}>
            <PageTitle title={I18n.privacy} />
            {/**Title */}
            <Typography variant="h6" component="h2" gutterBottom>
                Conditions d'utilisation du site Web Version 1.0
            </Typography>
            <Typography variant="body1" gutterBottom>
                &emsp; Le site Web soinsdom situé à soinsdom.com est une œuvre
                protégée appartenant à soinsdom. Certaines fonctionnalités du
                site peuvent être soumises à des directives, conditions ou
                règles supplémentaires, qui seront publiées sur le site en
                relation avec ces fonctionnalités.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Tous ces termes, directives et règles supplémentaires sont
                incorporés par référence dans les présentes Conditions.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Ces conditions d'utilisation décrivent les termes et conditions
                juridiquement contraignants qui supervisent votre utilisation du
                site. EN VOUS CONNECTANT AU SITE, VOUS ÊTES CONFORMÉ AUX CES
                CONDITIONS et vous déclarez que vous avez l'autorité et la
                capacité de conclure ces Conditions. VOUS DEVEZ AVOIR AU MOINS
                18 ANS POUR ACCÉDER AU SITE. SI VOUS N'ACCEPTEZ PAS TOUTES LES
                DISPOSITIONS DE CES CONDITIONS, NE VOUS CONNECTEZ PAS ET / OU
                N'UTILISEZ PAS LE SITE.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Ces conditions nécessitent l'utilisation de la section 10.2
                d'arbitrage sur une base individuelle pour résoudre les
                différends et limitent également les recours disponibles en cas
                de litige.
            </Typography>
            {/**Title */}
            <Typography variant="h6" component="h2" gutterBottom>
                Accès au site
            </Typography>
            <Typography variant="body1" gutterBottom>
                &emsp; Sous réserve de ces conditions. La société vous accorde
                une licence limitée non transférable, non exclusive, révocable
                pour accéder au site uniquement pour votre usage personnel et
                non commercial.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Certaines restrictions. Les droits qui vous sont approuvés dans
                les présentes Conditions sont soumis aux restrictions suivantes:
                (a) vous ne devez pas vendre, louer, louer, transférer, céder,
                distribuer, héberger ou exploiter commercialement le Site; (b)
                vous ne devez pas modifier, faire des travaux dérivés,
                désassembler, recompiler ou faire de l'ingénierie inverse de
                toute partie du site; (c) vous ne devez pas accéder au site afin
                de créer un site Web similaire ou concurrentiel; et (d) sauf
                indication expresse dans les présentes, aucune partie du site ne
                peut être copiée, reproduite, distribuée, republiée,
                téléchargée, affichée, publiée ou transmise sous quelque forme
                ou par quelque moyen que ce soit, sauf indication contraire,
                toute future version, mise à jour ou tout autre ajout aux
                fonctionnalités du site sera soumis aux présentes conditions.
                Tous les droits d'auteur et autres avis de propriété sur le site
                doivent être conservés sur toutes les copies de celui-ci.
            </Typography>
            <Typography variant="body1" gutterBottom>
                La société se réserve le droit de modifier, suspendre ou
                interrompre le site avec ou sans préavis. Vous avez approuvé que
                la société ne sera pas tenue responsable envers vous ou tout
                tiers pour tout changement, interruption ou résiliation du site
                ou de toute partie.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Pas de support ou de maintenance. Vous convenez que la Société
                n'aura aucune obligation de vous fournir une assistance en
                relation avec le Site.
            </Typography>
            <Typography variant="body1" gutterBottom>
                À l’exclusion de tout contenu utilisateur que vous pouvez
                fournir, vous êtes conscient que tous les droits de propriété
                intellectuelle, y compris les droits d’auteur, les brevets, les
                marques et les secrets commerciaux, sur le site et son contenu
                sont la propriété de la société ou des fournisseurs de la
                société. Notez que ces Conditions et l'accès au Site ne vous
                confèrent aucun droit, titre ou intérêt dans ou à aucun droit de
                propriété intellectuelle, à l'exception des droits d'accès
                limités exprimés dans la Section 2.1. La société et ses
                fournisseurs se réservent tous les droits non accordés dans les
                présentes conditions.
            </Typography>
            {/**Title */}
            <Typography variant="h6" component="h2" gutterBottom>
                Contenu utilisateur
            </Typography>
            <Typography variant="body1" gutterBottom>
                &emsp; Contenu utilisateur. «Contenu utilisateur» désigne toutes
                les informations et tous les contenus qu'un utilisateur soumet
                au site. Vous êtes exclusivement responsable de votre contenu
                utilisateur. Vous supportez tous les risques associés à
                l'utilisation de votre contenu utilisateur. Vous certifiez par
                la présente que votre contenu utilisateur ne viole pas notre
                politique d'utilisation acceptable. Vous ne pouvez pas
                représenter ou laisser entendre à des tiers que votre contenu
                utilisateur est de quelque manière fourni, parrainé ou approuvé
                par la société. Parce que vous êtes seul responsable de votre
                Contenu utilisateur, vous pouvez vous exposer à une
                responsabilité. La société n'est pas obligée de sauvegarder tout
                contenu utilisateur que vous publiez; De plus, votre Contenu
                utilisateur peut être supprimé à tout moment sans préavis. Vous
                êtes seul responsable de la réalisation de vos propres copies de
                sauvegarde de votre contenu utilisateur si vous le souhaitez.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Vous accordez par la présente à la Société une licence mondiale
                irréversible, non exclusive, libre de droits et entièrement
                payée pour reproduire, distribuer, afficher et exécuter
                publiquement, préparer des œuvres dérivées de, les incorporer
                dans d'autres œuvres, et autrement utiliser et exploiter votre
                contenu utilisateur, et à accorder des sous-licences des droits
                ci-dessus, uniquement dans le but d'inclure votre contenu
                utilisateur dans le site. Par la présente, vous renoncez de
                manière irréversible à toute réclamation et affirmation de
                droits moraux ou d'attribution à l'égard de votre contenu
                utilisateur.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Politique d'utilisation acceptable. Les conditions suivantes
                constituent notre «Politique d'utilisation acceptable»: Vous
                acceptez de ne pas utiliser le Site pour collecter, télécharger,
                transmettre, afficher ou distribuer tout Contenu utilisateur (i)
                qui viole tout droit de tiers ou toute propriété intellectuelle
                ou droit de propriété; (ii) qui est illégal, harcelant, abusif,
                délictueux, menaçant, nuisible, envahissant la vie privée
                d'autrui, vulgaire, diffamatoire, faux, intentionnellement
                trompeur, commercial diffamatoire, pornographique, obscène,
                manifestement offensant, favorise le racisme, le sectarisme, la
                haine ou physique préjudice de quelque nature que ce soit contre
                tout groupe ou individu; (iii) qui est préjudiciable aux mineurs
                de quelque manière que ce soit; ou (iv) qui est en violation de
                toute loi, réglementation, ou obligations ou restrictions
                imposées par un tiers.
            </Typography>
            <Typography variant="body1" gutterBottom>
                In addition, you agree not to: (i) upload, transmit, or
                distribute to or through the Site any software intended to
                damage or alter a computer system or data; (ii) send through the
                Site unsolicited or unauthorized advertising, promotional
                materials, junk mail, spam, chain letters, pyramid schemes, or
                any other form of duplicative or unsolicited messages; (iii) use
                the Site to harvest, collect, gather or assemble information or
                data regarding other users without their consent; (iv) interfere
                with, disrupt, or create an undue burden on servers or networks
                connected to the Site, or violate the regulations, policies or
                procedures of such networks; (v) attempt to gain unauthorized
                access to the Site, whether through password mining or any other
                means; (vi) harass or interfere with any other user’s use and
                enjoyment of the Site; or (vi) use software or automated agents
                or scripts to produce multiple accounts on the Site, or to
                generate automated searches, requests, or queries to the Site.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Nous nous réservons le droit d'examiner tout contenu utilisateur
                et d'enquêter et / ou de prendre les mesures appropriées contre
                vous à notre seule discrétion si vous enfreignez la politique
                d'utilisation acceptable ou toute autre disposition des
                présentes conditions ou créez autrement une responsabilité pour
                nous ou toute autre personne. Une telle action peut inclure la
                suppression ou la modification de votre contenu utilisateur, la
                résiliation de votre compte conformément à la section 8 et / ou
                votre signalement aux autorités chargées de l'application de la
                loi.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Si vous fournissez à la société des commentaires ou des
                suggestions concernant le site, vous attribuez par la présente à
                la société tous les droits sur ces commentaires et acceptez que
                la société ait le droit d'utiliser et d'exploiter pleinement ces
                commentaires et informations connexes de la manière qu'elle juge
                appropriée. La société traitera tout commentaire que vous lui
                fournissez comme non confidentiel et non exclusif.
            </Typography>
            <Typography variant="body1" gutterBottom>
                731 / 5000 Résultats de traduction Vous acceptez d'indemniser et
                de dégager de toute responsabilité la Société et ses dirigeants,
                employés et agents, y compris les frais et honoraires d'avocat,
                de toute réclamation ou demande faite par un tiers en raison ou
                découlant de (a) votre utilisation du Site, (b) votre violation
                des présentes Conditions, (c) votre violation des lois ou
                règlements applicables ou (d) votre Contenu utilisateur. La
                société se réserve le droit d'assumer la défense et le contrôle
                exclusifs de toute question pour laquelle vous êtes tenu de nous
                indemniser, et vous acceptez de coopérer avec notre défense de
                ces réclamations. Vous acceptez de ne régler aucune question
                sans le consentement écrit préalable de la Société. La Société
                déploiera des efforts raisonnables pour vous informer de toute
                réclamation, action ou procédure dès qu'elle en aura
                connaissance.
            </Typography>
            {/**Title */}
            <Typography variant="h6" component="h2" gutterBottom>
                Liens et publicités de tiers; Autres utilisateurs
            </Typography>
            <Typography variant="body1" gutterBottom>
                &emsp; Liens et publicités de tiers. Le site peut contenir des
                liens vers des sites Web et des services tiers et / ou afficher
                des publicités pour des tiers. Ces liens et publicités tiers ne
                sont pas sous le contrôle de la société et la société n'est pas
                responsable des liens et publicités de tiers. La société ne
                fournit l'accès à ces liens et publicités de tiers que pour
                votre commodité, et ne révise, n'approuve, ne surveille,
                n'approuve, ne garantit ni ne fait aucune représentation
                concernant les liens et publicités de tiers. Vous utilisez tous
                les liens et publicités de tiers à vos propres risques et devez
                appliquer un niveau approprié de prudence et de discrétion pour
                le faire. Lorsque vous cliquez sur l'un des liens et publicités
                de tiers, les conditions et politiques applicables du tiers
                s'appliquent, y compris les pratiques de confidentialité et de
                collecte de données du tiers.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Autres utilisateurs. Chaque utilisateur du site est seul
                responsable de tout ou partie de son propre contenu utilisateur.
                Parce que nous ne contrôlons pas le contenu utilisateur, vous
                reconnaissez et acceptez que nous ne sommes pas responsables de
                tout contenu utilisateur, qu'il soit fourni par vous ou par
                d'autres. Vous acceptez que la Société ne sera pas responsable
                de toute perte ou dommage encouru à la suite de telles
                interactions. En cas de litige entre vous et un utilisateur du
                site, nous n'avons aucune obligation de nous impliquer.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Vous libérez et déchargez pour toujours la Société et nos
                dirigeants, employés, agents, successeurs et ayants droit de, et
                renoncez et renoncez par la présente à chaque différend,
                réclamation, controverse, demande, droit, obligation,
                responsabilité, passé, présent et futur, action et cause
                d'action de toute sorte et nature, qui est survenue ou découle
                directement ou indirectement du site, ou qui se rapporte
                directement ou indirectement au site. Si vous êtes un résident
                de Californie, vous renoncez par la présente à la section 1542
                du code civil californien en relation avec ce qui précède, qui
                stipule: "une décharge générale ne s'étend pas aux créances que
                le créancier ne sait pas ou soupçonne d'exister en sa faveur à
                la le moment de l'exécution de la mainlevée, qui, s'il en avait
                connaissance, doit avoir eu une incidence importante sur son
                règlement avec le débiteur. "
            </Typography>
            <Typography variant="body1" gutterBottom>
                Cookies et balises Web. Comme tout autre site Web, soinsdom
                utilise des «cookies». Ces cookies sont utilisés pour stocker
                des informations, y compris les préférences des visiteurs et les
                pages du site Web auxquelles le visiteur a accédé ou visité. Les
                informations sont utilisées pour optimiser l'expérience des
                utilisateurs en personnalisant le contenu de notre page Web en
                fonction du type de navigateur des visiteurs et / ou d'autres
                informations.
            </Typography>
            {/**Title */}
            <Typography variant="h6" component="h2" gutterBottom>
                Avis de non-responsabilité
            </Typography>

            <Typography variant="body1" gutterBottom>
                &emsp; Le site est fourni sur une base «tel quel» et «tel que
                disponible», et la société et nos fournisseurs déclinent
                expressément toute garantie et condition de quelque nature que
                ce soit, expresse, implicite ou statutaire, y compris toutes les
                garanties ou conditions de qualité marchande , aptitude à un
                usage particulier, titre, plaisir tranquille, précision ou
                non-contrefaçon. Nous et nos fournisseurs ne garantissons pas
                que le site répondra à vos besoins, sera disponible de manière
                ininterrompue, opportune, sécurisée ou sans erreur, ou sera
                précis, fiable, exempt de virus ou autre code nuisible, complet,
                légal , ou sûr. Si la loi applicable exige des garanties
                concernant le site, toutes ces garanties sont limitées en durée
                à quatre-vingt-dix (90) jours à compter de la date de première
                utilisation.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Certaines juridictions n'autorisant pas l'exclusion des
                garanties implicites, l'exclusion ci-dessus peut ne pas
                s'appliquer à vous. Certaines juridictions n'autorisent pas les
                limitations sur la durée d'une garantie implicite, de sorte que
                la limitation ci-dessus peut ne pas s'appliquer à vous.
            </Typography>
            {/**Title */}
            <Typography variant="h6" component="h2" gutterBottom>
                Limitation de responsabilité
            </Typography>

            <Typography variant="body1" gutterBottom>
                &emsp; Dans toute la mesure permise par la loi, en aucun cas la
                société ou nos fournisseurs ne pourront être tenus responsables
                envers vous ou tout tiers pour toute perte de profit, perte de
                données, coût d'achat de produits de substitution, ou tout autre
                indirect, consécutif, exemplaire, accessoire, des dommages
                spéciaux ou punitifs découlant de ou liés à ces conditions ou
                votre utilisation ou incapacité à utiliser le site même si
                l'entreprise a été informée de la possibilité de tels dommages.
                L'accès et l'utilisation du site sont à votre discrétion et à
                vos propres risques, et vous serez seul responsable de tout
                dommage causé à votre appareil ou système informatique, ou de la
                perte de données en résultant.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Dans la mesure maximale permise par la loi, nonobstant toute
                disposition contraire contenue dans les présentes, notre
                responsabilité envers vous pour tout dommage découlant de ou lié
                à cet accord, sera à tout moment limitée à un maximum de
                cinquante dollars américains (50 $ US). L'existence de plus
                d'une réclamation n'élargira pas cette limite. Vous acceptez que
                nos fournisseurs n'auront aucune responsabilité de quelque
                nature que ce soit découlant de ou liée à cet accord.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Certaines juridictions n'autorisent pas la limitation ou
                l'exclusion de responsabilité pour les dommages accessoires ou
                consécutifs, de sorte que la limitation ou l'exclusion ci-dessus
                peut ne pas s'appliquer à vous.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Durée et résiliation. Sous réserve de cette section, ces
                conditions resteront pleinement en vigueur pendant que vous
                utilisez le site. Nous pouvons suspendre ou résilier vos droits
                d'utiliser le site à tout moment pour quelque raison que ce soit
                à notre seule discrétion, y compris pour toute utilisation du
                site en violation des présentes conditions. À la résiliation de
                vos droits en vertu des présentes conditions, votre compte et
                votre droit d'accès et d'utilisation du site prendront fin
                immédiatement. Vous comprenez que toute résiliation de votre
                compte peut impliquer la suppression de votre contenu
                utilisateur associé à votre compte de nos bases de données en
                direct. La société n'aura aucune responsabilité envers vous pour
                toute résiliation de vos droits en vertu des présentes
                Conditions. Même après la résiliation de vos droits en vertu des
                présentes Conditions, les dispositions suivantes de ces
                Conditions resteront en vigueur: Sections 2 à 2.5, Section 3 et
                Sections 4 à 10.
            </Typography>
            {/** Title */}
            <Typography variant="h6" component="h2" gutterBottom>
                Politique de copyright.
            </Typography>

            <Typography variant="body1" gutterBottom>
                &emsp; La société respecte la propriété intellectuelle d'autrui
                et demande aux utilisateurs de notre site de faire de même. Dans
                le cadre de notre site, nous avons adopté et mis en œuvre une
                politique respectant la loi sur les droits d'auteur qui prévoit
                la suppression de tout matériel contrefait et la résiliation des
                utilisateurs de notre site en ligne qui enfreignent à plusieurs
                reprises les droits de propriété intellectuelle, y compris les
                droits d'auteur. Si vous pensez que l'un de nos utilisateurs,
                par le biais de l'utilisation de notre site, enfreint
                illégalement le ou les droits d'auteur d'une œuvre et souhaitez
                faire supprimer le matériel prétendument contrefait, les
                informations suivantes sous la forme d'une notification écrite
                (conformément à 17 USC § 512 (c)) doivent être fournis à notre
                agent des droits d'auteur désigné:
            </Typography>
            <List>
                <ListItem>votre signature physique ou électronique;</ListItem>
                <ListItem>
                    l'identification des œuvres protégées par le droit d'auteur
                    que vous prétendez avoir été violées;
                </ListItem>
                <ListItem>
                    l'identification du matériel sur nos services que vous
                    prétendez enfreindre et que vous nous demandez de supprimer;
                </ListItem>
                <ListItem>
                    des informations suffisantes pour nous permettre de
                    localiser ce matériel;
                </ListItem>
                <ListItem>
                    votre adresse, numéro de téléphone et adresse e-mail;
                </ListItem>
                <ListItem>
                    une déclaration selon laquelle vous croyez de bonne foi que
                    l'utilisation du matériel répréhensible n'est pas autorisée
                    par le titulaire du droit d'auteur, son agent ou en vertu de
                    la loi; et
                </ListItem>
                <ListItem>
                    une déclaration selon laquelle les informations contenues
                    dans la notification sont exactes, et sous peine de parjure,
                    que vous êtes soit le propriétaire du droit d'auteur qui
                    aurait été violé, soit que vous êtes autorisé à agir au nom
                    du titulaire du droit d'auteur.
                </ListItem>
            </List>
            <Typography variant="body1" gutterBottom>
                Veuillez noter que, conformément à 17 U.S.C. § 512 (f), toute
                fausse déclaration de fait important dans une notification
                écrite soumet automatiquement la partie plaignante à la
                responsabilité de tout dommage, frais et honoraires d'avocat
                encourus par nous en relation avec la notification écrite et
                l'allégation de violation du droit d'auteur.
            </Typography>
            {/**Title */}
            <Typography variant="h6" component="h2" gutterBottom>
                Général
            </Typography>
            <Typography variant="body1" gutterBottom>
                &emsp; Ces conditions sont sujettes à des révisions
                occasionnelles, et si nous apportons des modifications
                substantielles, nous pouvons vous en informer en vous envoyant
                un e-mail à la dernière adresse e-mail que vous nous avez
                fournie et / ou en affichant bien en évidence un avis des
                modifications sur notre Site. Vous êtes responsable de nous
                fournir votre adresse e-mail la plus récente. Dans le cas où la
                dernière adresse e-mail que vous nous avez fournie n'est pas
                valide, notre envoi de l'e-mail contenant un tel avis
                constituera néanmoins une notification effective des changements
                décrits dans l'avis. Toute modification de ces Conditions
                entrera en vigueur au plus tôt dans les trente (30) jours civils
                suivant notre envoi d'un avis par e-mail à vous ou trente (30)
                jours civils après notre publication d'avis des changements sur
                notre site. Ces modifications entreront en vigueur immédiatement
                pour les nouveaux utilisateurs de notre site. L'utilisation
                continue de notre site après notification de ces changements
                indiquera votre reconnaissance de ces changements et votre
                accord à être lié par les termes et conditions de ces
                changements. Règlement des différends. Veuillez lire
                attentivement cette convention d'arbitrage. Cela fait partie de
                votre contrat avec la société et affecte vos droits. Il contient
                des procédures pour un ARBITRAGE OBLIGATOIRE OBLIGATOIRE ET UNE
                RENONCIATION À L'ACTION COLLECTIVE.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Applicabilité de la convention d'arbitrage. Toutes les
                réclamations et litiges liés aux Conditions ou à l'utilisation
                de tout produit ou service fourni par la Société qui ne peuvent
                être résolus de manière informelle ou devant un tribunal des
                petites créances seront résolus par arbitrage exécutoire sur une
                base individuelle selon les termes de la présente Convention
                d'arbitrage. Sauf convention contraire, toutes les procédures
                d'arbitrage se déroulent en anglais. La présente convention
                d'arbitrage s'applique à vous et à la société, ainsi qu'à toutes
                les filiales, sociétés affiliées, agents, employés,
                prédécesseurs intéressés, successeurs et ayants droit, ainsi
                qu'à tous les utilisateurs ou bénéficiaires autorisés ou non
                autorisés de services ou de produits fournis en vertu des
                conditions.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Obligation de notification et résolution informelle des
                différends. Avant que l'une ou l'autre des parties puisse
                demander l'arbitrage, la partie doit d'abord envoyer à l'autre
                partie un avis de différend écrit décrivant la nature et le
                fondement de la réclamation ou du différend, ainsi que la
                réparation demandée. Un avis à la société doit être adressé à:
                Tunisie. Une fois la notification reçue, vous et la société
                pouvez tenter de résoudre la réclamation ou le différend de
                manière informelle. Si vous et la société ne résolvez pas la
                réclamation ou le différend dans les trente (30) jours suivant
                la réception de l'avis, l'une ou l'autre des parties peut
                entamer une procédure d'arbitrage. Le montant de toute offre de
                règlement faite par une partie ne peut être divulgué à l'arbitre
                qu'après que celui-ci ait déterminé le montant de la sentence
                auquel l'une ou l'autre des parties a droit.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Règles d'arbitrage. L'arbitrage doit être initié par
                l'intermédiaire de l'American Arbitration Association, un
                fournisseur de solutions alternatives de résolution des litiges
                qui propose l'arbitrage comme indiqué dans cette section. Si AAA
                n'est pas disponible pour arbitrer, les parties conviennent de
                sélectionner un fournisseur ADR alternatif. Les règles du
                fournisseur ADR régiront tous les aspects de l'arbitrage, sauf
                dans la mesure où ces règles sont en conflit avec les
                Conditions. Les règles d'arbitrage des consommateurs de l'AAA
                régissant l'arbitrage sont disponibles en ligne sur adr.org ou
                en appelant l'AAA au 1-800-778-7879. L'arbitrage sera conduit
                par un arbitre unique et neutre. Toute réclamation ou tout
                litige où le montant total de la sentence demandée est inférieur
                à dix mille dollars américains (10000,00 USD) peut être résolu
                par un arbitrage exécutoire sans comparution, au choix de la
                partie qui demande réparation. Pour les réclamations ou litiges
                pour lesquels le montant total de la sentence demandée est de
                dix mille dollars américains (10000,00 $ US) ou plus, le droit à
                une audience sera déterminé par le règlement d'arbitrage. Toute
                audience aura lieu dans un endroit situé dans un rayon de 100
                miles de votre résidence, à moins que vous ne résidiez en dehors
                des États-Unis et à moins que les parties n'en conviennent
                autrement. Si vous résidez en dehors des États-Unis, l'arbitre
                donnera aux parties un préavis raisonnable de la date, de
                l'heure et du lieu de toute audition. Tout jugement sur la
                sentence rendue par l'arbitre peut être inscrit devant tout
                tribunal compétent. Si l'arbitre vous accorde une sentence qui
                est supérieure à la dernière offre de règlement que la Société
                vous a faite avant le début de l'arbitrage, la Société vous
                paiera le plus élevé de la sentence ou 2 500,00 $. Chaque partie
                supportera ses propres frais et débours résultant de l'arbitrage
                et paiera une part égale des honoraires et des frais du
                fournisseur de REL.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Règles supplémentaires pour l'arbitrage basé sur la
                non-comparution. Si un arbitrage sans comparution est choisi,
                l'arbitrage sera mené par téléphone, en ligne et / ou basé
                uniquement sur des soumissions écrites; la manière spécifique
                sera choisie par la partie qui engage l'arbitrage. L'arbitrage
                n'impliquera aucune comparution personnelle des parties ou des
                témoins, sauf accord contraire des parties.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Limites de temps. Si vous ou la Société poursuivez l'arbitrage,
                l'action d'arbitrage doit être initiée et / ou demandée dans le
                délai de prescription et dans tout délai imposé en vertu des
                règles de l'AAA pour la réclamation pertinente.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Authority of Arbitrator. If arbitration is initiated, the
                arbitrator will decide the rights and liabilities of you and the
                Company, and the dispute will not be consolidated with any other
                matters or joined with any other cases or parties. The
                arbitrator shall have the authority to grant motions dispositive
                of all or part of any claim. The arbitrator shall have the
                authority to award monetary damages, and to grant any
                non-monetary remedy or relief available to an individual under
                applicable law, the AAA Rules, and the Terms. The arbitrator
                shall issue a written award and statement of decision describing
                the essential findings and conclusions on which the award is
                based. The arbitrator has the same authority to award relief on
                an individual basis that a judge in a court of law would have.
                The award of the arbitrator is final and binding upon you and
                the Company.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Renonciation au procès devant jury. LES PARTIES RENONCENT PAR LA
                PRÉSENTE À LEURS DROITS CONSTITUTIONNELS ET STATUTAIRES D'ALLER
                AU TRIBUNAL ET DE TENIR UN PROCÈS DEVANT UN JUGE OU UN JURY,
                choisissant plutôt que toutes les réclamations et différends
                soient résolus par arbitrage en vertu de la présente convention
                d'arbitrage. Les procédures d'arbitrage sont généralement plus
                limitées, plus efficaces et moins coûteuses que les règles
                applicables dans un tribunal et sont soumises à un examen très
                limité par un tribunal. Dans le cas où un litige surviendrait
                entre vous et la Société dans un tribunal d'État ou fédéral dans
                le cadre d'une action en annulation ou en exécution d'une
                sentence arbitrale ou autre, VOUS ET LA SOCIÉTÉ RENONCEZ À TOUS
                DROITS À UN PROCÈS JURY, choisissant plutôt que le différend
                soit résolu. par un juge.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Renonciation aux actions collectives ou consolidées. Toutes les
                réclamations et litiges dans le cadre de la présente convention
                d'arbitrage doivent être arbitrés ou litigieux sur une base
                individuelle et non sur une base collective, et les réclamations
                de plus d'un client ou utilisateur ne peuvent être arbitrées ou
                litigieuses conjointement ou consolidées avec celles de tout
                autre client. ou utilisateur.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Confidentialité. Tous les aspects de la procédure d'arbitrage
                sont strictement confidentiels. Les parties conviennent de
                maintenir la confidentialité sauf disposition contraire de la
                loi. Ce paragraphe n'empêchera pas une partie de soumettre à un
                tribunal les informations nécessaires pour faire appliquer le
                présent accord, pour exécuter une sentence arbitrale ou pour
                demander une injonction ou une réparation équitable.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Divisibilité. Si une partie ou des parties de la présente
                convention d'arbitrage sont jugées invalides ou inapplicables
                par un tribunal compétent en vertu de la loi, cette ou ces
                parties spécifiques seront sans force et sans effet et seront
                rompues et le reste de l'accord sera continuer avec force et
                effet.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Droit de renonciation. Tout ou partie des droits et limitations
                énoncés dans la présente convention d'arbitrage peuvent être
                annulés par la partie contre laquelle la réclamation est
                invoquée. Une telle renonciation ne renoncera ni n'affectera
                aucune autre partie de la présente convention d'arbitrage.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Survie de l'accord. Cette convention d'arbitrage survivra à la
                résiliation de votre relation avec la société.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Cour des petites créances. Néanmoins, ce qui précède, vous ou la
                Société pouvez intenter une action individuelle devant le
                tribunal des petites créances.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Aide d'urgence équitable. Quoi qu'il en soit, l'une ou l'autre
                des parties peut demander une réparation équitable d'urgence
                devant un tribunal d'État ou fédéral afin de maintenir le statu
                quo en attendant l'arbitrage. Une demande de mesures provisoires
                ne sera pas considérée comme une renonciation à tout autre droit
                ou obligation en vertu de la présente convention d'arbitrage.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Réclamations non soumises à l'arbitrage. Nonobstant ce qui
                précède, les réclamations pour diffamation, violation de la loi
                sur la fraude et les abus informatiques et la contrefaçon ou le
                détournement du brevet, du droit d’auteur, de la marque ou des
                secrets commerciaux de l’autre partie ne seront pas soumises au
                présent accord d’arbitrage.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Dans toutes les circonstances où la convention d'arbitrage
                ci-dessus permet aux parties de plaider devant les tribunaux,
                les parties conviennent par la présente de se soumettre à la
                compétence personnelle des tribunaux situés dans le comté des
                Pays-Bas, en Californie, à ces fins.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Le Site peut être soumis aux lois américaines sur le contrôle
                des exportations et peut être soumis à des réglementations
                d'exportation ou d'importation dans d'autres pays. Vous acceptez
                de ne pas exporter, réexporter ou transférer, directement ou
                indirectement, les données techniques américaines acquises
                auprès de la Société, ou tout produit utilisant ces données, en
                violation des lois ou réglementations américaines sur
                l'exportation.
            </Typography>
            <Typography variant="body1" gutterBottom>
                La société est située à l'adresse indiquée à la section 10.8. Si
                vous résidez en Californie, vous pouvez signaler vos plaintes à
                l'Unité d'assistance aux plaintes de la Division des produits de
                consommation du Département de la consommation de Californie en
                les contactant par écrit au 400 R Street, Sacramento, CA 95814,
                ou par téléphone au (800 ) 952-5210.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Communications électroniques. Les communications entre vous et
                la société utilisent des moyens électroniques, que vous
                utilisiez le site ou que vous nous envoyiez des e-mails, ou que
                la société publie des avis sur le site ou communique avec vous
                par e-mail. À des fins contractuelles, vous (a) consentez à
                recevoir des communications de la Société sous forme
                électronique; et (b) acceptez que tous les termes et conditions,
                accords, avis, divulgations et autres communications que la
                Société vous fournit par voie électronique satisfont à toute
                obligation légale que ces communications satisferaient si elles
                étaient rédigées sur papier.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Termes entiers. Ces conditions constituent l'intégralité de
                l'accord entre vous et nous concernant l'utilisation du site.
                Notre incapacité à exercer ou à appliquer tout droit ou
                disposition des présentes Conditions ne doit pas fonctionner
                comme une renonciation à ce droit ou à cette disposition. Les
                titres des sections de ces Conditions sont fournis à titre
                indicatif uniquement et n'ont aucun effet légal ou contractuel.
                Le mot «y compris» signifie «y compris sans limitation». Si une
                disposition de ces Conditions est jugée invalide ou
                inapplicable, les autres dispositions de ces Conditions ne
                seront pas altérées et la disposition invalide ou inapplicable
                sera réputée modifiée afin qu'elle soit valide et exécutoire
                dans la mesure maximale autorisée par la loi. Votre relation
                avec la société est celle d'un entrepreneur indépendant, et
                aucune des parties n'est un agent ou un partenaire de l'autre.
                Ces Conditions, ainsi que vos droits et obligations dans les
                présentes, ne peuvent être cédés, sous-traités, délégués ou
                autrement transférés par vous sans le consentement écrit
                préalable de la Société, et toute tentative de cession, de
                sous-traitance, de délégation ou de transfert en violation de ce
                qui précède sera nulle et néant. La société peut librement
                attribuer ces conditions. Les termes et conditions énoncés dans
                les présentes Conditions lient les cessionnaires.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Votre vie privée. S'il vous plait lisez notre politique de
                confidentialité.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Informations sur les droits d'auteur / marques déposées.
                Copyright ©. Tous les droits sont réservés. Toutes les marques,
                logos et marques de service affichés sur le site sont notre
                propriété ou la propriété d'autres tiers. Vous n'êtes pas
                autorisé à utiliser ces marques sans notre consentement écrit
                préalable ou le consentement de ce tiers qui peut être
                propriétaire des marques.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Informations de contact Adresse: Tunisie Courriel:
                daraque07@hotmail.fr
            </Typography>
        </div>
    );
};
export default Conditions;
