import React, { ReactNode } from "react";
import classes from "./PageTitle.module.css";
import Typography from "@mui/material/Typography";
type pageTitlePropsType = {
  title: string;
  children?: ReactNode;
};
const pageTitle: React.FC<pageTitlePropsType> = (props: pageTitlePropsType): React.ReactElement => {
  return (
    <div className={classes.pageTitleBar}>
      <Typography variant="h6" component="h1" style={{ color: "#FFFFFF" }} align="center">
        {props.title}
      </Typography>
      {props.children ? props.children: null}
    </div>
  );
};
export default pageTitle;
