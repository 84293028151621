import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    useMediaQuery,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import React, { useEffect, useState } from "react";
import LocalStorageUtil from "../../../../utils/localStorageUtil";
import AppointmentDto from "../../../../ws/appointment/AppointmentDto";
import RdvQueueRow from "./rdvQueueRow/RdvQueueRow";
import moment from "moment";
import DataLoading from "../../../ui/dataLoading/DataLoading";
import I18n from "../../../../config/i18n/I18n";
import AlertDialog from "../../../ui/alertDialog/AlertDialog";
import ProfessionalWebService from "../../../../ws/professional/Professional.ws";
import classes from "./RdvQueue.module.scss";

const RdvQueue = () => {
    const matches = useMediaQuery("(min-width:650px)");
    const [appointments, setAppointments] = useState<Array<AppointmentDto>>([]);
    const [openNetworkAlert, setOpenNetworkAlert] = useState<boolean>(false);
    /**
     * Close alert handler
     */
    const closeNetworkAlertHandler = () => {
        setOpenNetworkAlert(false);
    };
    const [progress, setProgress] = useState<boolean>(true);
    useEffect(() => {
        const userId = LocalStorageUtil.getUserId();
        if (userId !== undefined) {
            ProfessionalWebService.getPendingAppointment(userId).then(
                (resp) => {
                    setAppointments(resp.data);
                    setProgress(false);
                },
                (error) => {
                    setOpenNetworkAlert(true);
                }
            );
        }
    }, []);
    const appointmentCallHandler = (appointmentId: string) => {
        setAppointments(
            appointments.filter((item) => item.id != appointmentId)
        );
    };
    if (progress) {
        return <DataLoading message={I18n.loadingDataMessage} />;
    } else {
        return (
            <div className={classes.RDVQueueContainer}>
                <AlertDialog
                    open={openNetworkAlert}
                    onClick={closeNetworkAlertHandler}
                    title={I18n.networkErrorTitle}
                    content={I18n.networkErrorContent}
                />
                <Paper>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">
                                        {String(I18n.id).toUpperCase()}
                                    </TableCell>
                                    {matches ? (
                                        <>
                                            <TableCell align="center">
                                                {String(
                                                    I18n.fullName
                                                ).toUpperCase()}
                                            </TableCell>
                                            <TableCell align="center">
                                                {String(
                                                    I18n.date
                                                ).toUpperCase()}
                                            </TableCell>
                                        </>
                                    ) : null}
                                    <TableCell align="center">
                                        {String(I18n.details).toUpperCase()}
                                    </TableCell>
                                    <TableCell align="center">
                                        {String(I18n.actions).toUpperCase()}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {appointments.map((item) => (
                                    <RdvQueueRow
                                        appointment={item}
                                        key={item.id}
                                        id={String(item.id)}
                                        fullName={item.patient.fullName}
                                        date={moment(
                                            item.appointmentDate
                                        ).format("DD-MM-YYYY")}
                                        appointmentCallHandler={
                                            appointmentCallHandler
                                        }
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* <TablePagination/> */}
                </Paper>
            </div>
        );
    }
};
export default RdvQueue;
