import React, { useState } from "react";
import classes from "./Contact.module.css";
import PageTitle from "../../features/ui/pageTitle/PageTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import I18n from "../../config/i18n/I18n";
import ContactWebService from "../../ws/contact/Contact.ws";
import ContactModel from "../../model/Contact.model";
type Props = {};
type ContactStateType = { contact: ContactModel };
//TODO from validation
const Contact = (props: Props) => {
    const [contact, setContact] = useState({
        email: "",
        fullName: "",
        object: "",
        message: "",
    });
    const senContactMessage = () => {
        ContactWebService.createContact(contact).then((resp) => {
            console.log(resp);
        });
    };
    const updateContactState = (name: string, value: string) => {
        setContact((contact) => ({
            ...contact,
            [name]: value,
        }));
    };

    return (
        <>
            <PageTitle title={I18n.contactUs} />
            <div className={classes.contactForm}>
                <TextField
                    label={I18n.email}
                    variant="outlined"
                    type="email"
                    onChange={(event) =>
                        updateContactState("email", event.target.value)
                    }
                />
                <TextField
                    label={I18n.lastname + " & " + I18n.firstname}
                    variant="outlined"
                    onChange={(event) =>
                        updateContactState("fullName", event.target.value)
                    }
                />
                <TextField
                    label={I18n.object}
                    variant="outlined"
                    onChange={(event) =>
                        updateContactState("object", event.target.value)
                    }
                />
                <TextField
                    label={I18n.message}
                    variant="outlined"
                    multiline
                    rows={4}
                    onChange={(event) =>
                        updateContactState("message", event.target.value)
                    }
                />
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={senContactMessage}
                >
                    {I18n.send}
                </Button>
            </div>
        </>
    );
};
export default Contact;
