import User from '../model/User.model';
export default class LocalStorageUtil {

    /**
     * Get user from localStorage
     */
    public static getUser(): User | null {
        const user = localStorage.getItem('user');
        if (user) {
            return JSON.parse(user);
        }
        return null;
    }
    /**
     * Get the id of the connected user
     */
    public static getUserId(): string | undefined {
        const user = localStorage.getItem('user');
        if (user) {
            const userObj: User = JSON.parse(user);
            return userObj.id;
        }
        return undefined;
    }
    /**
     * Check if a user is connected
     */
    public static isUserConnected():boolean{
        const isConnected=localStorage.getItem('isConnected');
        
        if(isConnected!==null){
            return JSON.parse(isConnected);
        }else{
            return false;
        }
    }
}