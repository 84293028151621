import React from "react";
import PageTitle from "../../features/ui/pageTitle/PageTitle";
import I18n from "../../config/i18n/I18n";
import ProfessionalSignIn from "../../features/professional/professionalSignIn/ProfessionalSignIn";
import { Box } from "@mui/material";

type Props = {};
const SignIn = (props: Props) => {
    return (
        <Box sx={{ padding: { xs: 2, md: 10 } }}>
            <PageTitle title={I18n.signIn} />
            <ProfessionalSignIn />
        </Box>
    );
};
export default SignIn;
