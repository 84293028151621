import React, { useState } from "react";
import classes from "./Appointment.module.scss";
import I18n from "../../config/i18n/I18n";
import PageTitle from "../../features/ui/pageTitle/PageTitle";
import RdvRequestStep from "../../features/appointment/rdvRequestStep/RdvRequestStep";
import RdvInformationStep from "../../features/appointment/rdvInformationStep/RdvInformationStep";
import { Position, Request } from "../../model/Appointment.model";
import Civility from "../../model/Civility.enum";
import Patient from "../../model/Patient.model";
import AppointmentWebService from "../../ws/appointment/Appointment.ws";
import { useHistory } from "react-router-dom";
import AlertDialog from "../../features/ui/alertDialog/AlertDialog";
import SoinsdomStepper from "../../features/ui/soinsdomStepper/SoinsdomStepper";
import OtpDialog from "../../features/ui/otpDialog/otpDialog";
import { Box } from "@mui/material";

const Appointment = () => {
    const [activeStep, setActiveStep] = useState(0);
    /**
     * SignedUp userId
     */
    const [appointmentId, setAppointmentId] = useState<string>("");
    /**
     * Otp modal
     */
    const [openOtpModal, setOpenOtpModal] = useState<boolean>(false);
    const [position, setPosition] = useState<Position>({
        address: "",
        lng: "",
        lat: "",
    });
    const [request, setRequest] = useState<Request>({
        careType: { id: 0, name: "" },
        appointmentDate: new Date(),
    });
    const [information, setInformation] = useState<Patient>({
        civility: Civility.Mr,
        email: "",
        mobile: "",
        birthDate: new Date(),
        fullName: "",
    });
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const router = useHistory();
    /**
     * Move forward to the next step
     */
    const nextStep = () => {
        setActiveStep((prevStep) => prevStep + 1);
    };
    /**
     * Close alert dialog
     */
    const closeAlertHandler = () => {
        setOpenAlert(false);
        router.push("");
    };
    /**
     * Close opt dialog
     */
    const closeOtpDialog = () => {
        setOpenOtpModal(false);
    };
    const createAppointment = () => {
        AppointmentWebService.createAppointment({
            position,
            request,
            information,
        })
            .then((resp) => {
                setAppointmentId(String(resp.data.id));
                if (process.env.REACT_APP_IS_SMS_ENABLED) {
                    setOpenOtpModal(true);
                } else {
                    AppointmentWebService.validateAppointment(
                        String(resp.data.id),
                        "0000"
                    ).then(
                        (resp) => {
                            if (resp.data === true) {
                                onOtpConfirm();
                            } else {
                                displayNetworkAlert();
                            }
                        },
                        (error) => {
                            displayNetworkAlert();
                        }
                    );
                }
            })
            .catch((err) => {
                setOpenNetworkAlert(true);
            });
    };
    /**
     * Update the rdv position state
     */
    const updateRdvPositionState = (position: Position) => {
        setPosition(position);
    };
    /**
     * Update the rdv request state
     */
    const updateRdvRequestState = (field: string, value: any) => {
        setRequest((request) => ({ ...request, [field]: value }));
    };
    /**
     * Update the rdv information state
     */
    const updateRdvInformationState = (field: string, value: string) => {
        setInformation((information) => ({ ...information, [field]: value }));
    };
    const [openNetworkAlert, setOpenNetworkAlert] = useState<boolean>(false);
    /**
     * Close alert handler
     */
    const closeNetworkAlertHandler = () => {
        setOpenNetworkAlert(false);
    };
    /**
     * Display network error alert
     */
    const displayNetworkAlert = () => {
        setOpenNetworkAlert(true);
    };
    /**
     * When otp dialog confirmed
     */
    const onOtpConfirm = () => {
        setOpenAlert(true);
    };
    return (
        <Box
            className={classes.RDVContainer}
            sx={{ padding: { xs: 2, md: 10 } }}
        >
            <OtpDialog
                open={openOtpModal}
                phone={information.mobile}
                closeDialog={closeOtpDialog}
                displayNetworkAlert={displayNetworkAlert}
                appointmentId={appointmentId}
                confirm={onOtpConfirm}
                isAppointmentConfirmation={true}
            />
            <AlertDialog
                open={openAlert}
                onClick={closeAlertHandler}
                title={I18n.rdvConfirmationTitle}
                content={I18n.rdvConfirmationContent}
            />
            <AlertDialog
                open={openNetworkAlert}
                onClick={closeNetworkAlertHandler}
                title={I18n.networkErrorTitle}
                content={I18n.networkErrorContent}
            />
            <PageTitle title={I18n.rdv} />
            <SoinsdomStepper
                activeStep={activeStep}
                steps={[I18n.myInformation, I18n.careRequest]}
            />
            {/** Form  part*/}
            <Box className={classes.rdvStepsContainer}>
                {/** Appointment information part */}
                {activeStep === 0 ? (
                    <RdvInformationStep
                        position={updateRdvPositionState}
                        information={updateRdvInformationState}
                        nextStep={nextStep}
                        informationStateObject={information}
                    />
                ) : null}
                {/** Request part */}

                {activeStep === 1 ? (
                    <RdvRequestStep
                        createAppointment={createAppointment}
                        request={updateRdvRequestState}
                    />
                ) : null}
            </Box>
            {/** Form part end*/}
        </Box>
    );
};
export default Appointment;
