import React from "react";
import I18n from "../../config/i18n/I18n";
import PageTitle from "../../features/ui/pageTitle/PageTitle";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import classes from "./Privacy.module.scss";
import { Box } from "@mui/material";
type Props = {};

const Privacy = (props: Props) => {
    return (
        <Box
            className={classes.privacyContainer}
            sx={{ padding: { xs: 2, md: 10 } }}
        >
            <PageTitle title={I18n.privacy} />
            <div>
                <div className={classes.privacyDescContainer}>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Politique de confidentialité : Dernière mise à jour: 06
                        décembre 2020
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        &emsp; Cette politique de confidentialité décrit nos
                        politiques et procédures sur la collecte, l'utilisation
                        et la divulgation de vos informations lorsque vous
                        utilisez le service et vous informe de vos droits à la
                        confidentialité et de la manière dont la loi vous
                        protège. Nous utilisons vos données personnelles pour
                        fournir et améliorer le service.
                        <br /> En utilisant le Service, vous acceptez la
                        collecte et l'utilisation d'informations conformément à
                        la présente politique de confidentialité.
                    </Typography>

                    <Typography variant="h6" component="h2" gutterBottom>
                        Interprétation et définitions
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        Interprétation
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        &emsp; Les mots dont la lettre initiale est en majuscule
                        ont des significations définies dans les conditions
                        suivantes. Les définitions suivantes ont la même
                        signification, qu’elles apparaissent au singulier ou au
                        pluriel.
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        Définitions
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Aux fins de cette politique de confidentialité:
                        <List>
                            <ListItem>
                                Compte signifie un compte unique créé pour que
                                vous puissiez accéder à notre service ou à des
                                parties de notre service.
                            </ListItem>
                            <ListItem>
                                La société (dénommée «la société», «nous»,
                                «notre» ou «notre» dans le présent contrat) fait
                                référence aux soinsdom.
                            </ListItem>
                            <ListItem>
                                Les cookies sont de petits fichiers placés sur
                                votre ordinateur, appareil mobile ou tout autre
                                appareil par un site Web, contenant les détails
                                de votre historique de navigation sur ce site
                                Web parmi ses nombreuses utilisations.
                            </ListItem>
                            <ListItem>Pays se réfère à: France</ListItem>
                            <ListItem>
                                Appareil désigne tout appareil pouvant accéder
                                au Service tel qu'un ordinateur, un téléphone
                                portable ou une tablette numérique.
                            </ListItem>
                            <ListItem>
                                Les données personnelles sont toute information
                                relative à une personne identifiée ou
                                identifiable.
                            </ListItem>
                            <ListItem>
                                Le service fait référence au site Web.
                            </ListItem>
                            <ListItem>
                                Prestataire de services désigne toute personne
                                physique ou morale qui traite les données pour
                                le compte de la Société. Il fait référence à des
                                sociétés tierces ou à des personnes employées
                                par la Société pour faciliter le Service, pour
                                fournir le Service au nom de la Société, pour
                                exécuter des services liés au Service ou pour
                                aider la Société à analyser la façon dont le
                                Service est utilisé.
                            </ListItem>
                            <ListItem>
                                Le service de médias sociaux tiers fait
                                référence à tout site Web ou à tout site Web de
                                réseau social via lequel un utilisateur peut se
                                connecter ou créer un compte pour utiliser le
                                service.
                            </ListItem>
                            <ListItem>
                                Les données d'utilisation font référence aux
                                données collectées automatiquement, soit
                                générées par l'utilisation du service, soit à
                                partir de l'infrastructure du service elle-même
                                (par exemple, la durée d'une visite de page).
                            </ListItem>

                            <ListItem>
                                Le site Web fait référence à soinsdom,
                                accessible depuis soinsdom.fr
                            </ListItem>
                            <ListItem>
                                Vous désigne la personne accédant ou utilisant
                                le Service, ou la société, ou toute autre entité
                                juridique au nom de laquelle cette personne
                                accède ou utilise le Service, selon le cas.
                            </ListItem>
                        </List>
                    </Typography>

                    <Typography variant="h6" component="h2" gutterBottom>
                        Collecte et utilisation de vos données personnelles
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        Types de données collectées
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Lors de l'utilisation de notre service, nous pouvons
                        vous demander de nous fournir certaines informations
                        personnellement identifiables qui peuvent être utilisées
                        pour vous contacter ou vous identifier. Les informations
                        personnellement identifiables peuvent inclure, mais sans
                        s'y limiter:
                        <List>
                            <ListItem>Adresse e-mail</ListItem>
                            <ListItem>Prénom et nom</ListItem>
                            <ListItem>Numéro de téléphone</ListItem>
                            <ListItem>
                                Adresse, État, Province, ZIP / Code postal,
                                Ville
                            </ListItem>
                            <ListItem>Données d'utilisation</ListItem>
                        </List>
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        Données d'utilisation
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Les données d'utilisation sont collectées
                        automatiquement lors de l'utilisation du service.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Les données d'utilisation peuvent inclure des
                        informations telles que l'adresse de protocole Internet
                        de votre appareil (par exemple, l'adresse IP), le type
                        de navigateur, la version du navigateur, les pages de
                        notre service que vous visitez, l'heure et la date de
                        votre visite, le temps passé sur ces pages, l'appareil
                        unique identifiants et autres données de diagnostic.
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        Lorsque vous accédez au service par ou via un appareil
                        mobile, nous pouvons collecter certaines informations
                        automatiquement, y compris, mais sans s'y limiter, le
                        type d'appareil mobile que vous utilisez, l'identifiant
                        unique de votre appareil mobile, l'adresse IP de votre
                        appareil mobile, votre mobile système d'exploitation, le
                        type de navigateur Internet mobile que vous utilisez,
                        les identifiants uniques de l'appareil et d'autres
                        données de diagnostic.
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        Nous pouvons également collecter des informations que
                        votre navigateur envoie chaque fois que vous visitez
                        notre service ou lorsque vous accédez au service par ou
                        via un appareil mobile.
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Technologies de suivi et cookies
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        Nous utilisons des cookies et des technologies de suivi
                        similaires pour suivre l'activité sur notre service et
                        stocker certaines informations. Les technologies de
                        suivi utilisées sont des balises, des balises et des
                        scripts pour collecter et suivre des informations et
                        pour améliorer et analyser notre service. Les
                        technologies que nous utilisons peuvent inclure:
                    </Typography>

                    <List>
                        <ListItem>
                            <Typography variant="body1" gutterBottom>
                                Cookies ou cookies de navigateur. Un cookie est
                                un petit fichier placé sur votre appareil. Vous
                                pouvez demander à votre navigateur de refuser
                                tous les cookies ou d'indiquer quand un cookie
                                est envoyé. Cependant, si vous n'acceptez pas
                                les cookies, il se peut que vous ne puissiez pas
                                utiliser certaines parties de notre service. À
                                moins que vous n'ayez ajusté les paramètres de
                                votre navigateur pour qu'il refuse les cookies,
                                notre service peut utiliser des cookies.
                            </Typography>
                        </ListItem>{" "}
                        <ListItem>
                            <Typography variant="body1" gutterBottom>
                                Cookies Flash. Certaines fonctionnalités de
                                notre service peuvent utiliser des objets
                                stockés localement (ou des cookies Flash) pour
                                collecter et stocker des informations sur vos
                                préférences ou votre activité sur notre service.
                                Les cookies Flash ne sont pas gérés par les
                                mêmes paramètres de navigateur que ceux utilisés
                                pour les cookies de navigateur. Pour plus
                                d'informations sur la manière de supprimer les
                                cookies Flash, veuillez lire "Où puis-je
                                modifier les paramètres de désactivation ou de
                                suppression des objets partagés locaux?"
                                disponible à l'adresse
                                https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
                            </Typography>
                        </ListItem>{" "}
                        <ListItem>
                            <Typography variant="body1" gutterBottom>
                                Balises Web. Certaines sections de notre service
                                et de nos e-mails peuvent contenir de petits
                                fichiers électroniques appelés balises Web
                                (également appelés gifs clairs, pixels
                                invisibles et gifs à pixel unique) qui
                                permettent à l'entreprise, par exemple, de
                                compter les utilisateurs qui ont visité ces
                                pages. ou a ouvert un e-mail et pour d'autres
                                statistiques de site Web connexes (par exemple,
                                enregistrer la popularité d'une certaine section
                                et vérifier l'intégrité du système et du
                                serveur).
                            </Typography>
                        </ListItem>
                    </List>

                    <Typography variant="body1" gutterBottom>
                        Les cookies peuvent être des cookies «persistants» ou
                        «de session». Les cookies persistants restent sur votre
                        ordinateur personnel ou appareil mobile lorsque vous
                        vous déconnectez, tandis que les cookies de session sont
                        supprimés dès que vous fermez votre navigateur Web. En
                        savoir plus sur les cookies: que sont les cookies?.
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        Nous utilisons à la fois des cookies de session et des
                        cookies persistants aux fins décrites ci-dessous:
                    </Typography>
                    <List>
                        <ListItem>
                            {" "}
                            <Typography
                                variant="h6"
                                component="h3"
                                gutterBottom
                            >
                                Cookies nécessaires / essentiels
                            </Typography>{" "}
                            Type: Cookies de session Administré par: nous
                            Objectif: Ces cookies sont essentiels pour vous
                            fournir les services disponibles sur le site Web et
                            pour vous permettre d'utiliser certaines de ses
                            fonctionnalités. Ils aident à authentifier les
                            utilisateurs et à empêcher l'utilisation frauduleuse
                            des comptes d'utilisateurs. Sans ces cookies, les
                            services que vous avez demandés ne peuvent pas être
                            fournis et nous n'utilisons ces cookies que pour
                            vous fournir ces services.
                        </ListItem>
                        <ListItem>
                            <Typography
                                variant="h6"
                                component="h3"
                                gutterBottom
                            >
                                Politique de cookies / Avis d'acceptation des
                                cookies
                            </Typography>
                            Type: Cookies persistants Administré par: nous
                            Finalité: Ces cookies identifient si les
                            utilisateurs ont accepté l'utilisation de cookies
                            sur le site Web.
                        </ListItem>
                        <ListItem>
                            <Typography
                                variant="h6"
                                component="h3"
                                gutterBottom
                            >
                                Cookies de fonctionnalité
                            </Typography>
                            Type: Cookies persistants Administré par: nous
                            Objectif: ces cookies nous permettent de nous
                            souvenir des choix que vous faites lorsque vous
                            utilisez le site Web, tels que la mémorisation de
                            vos informations de connexion ou de votre préférence
                            de langue. Le but de ces cookies est de vous offrir
                            une expérience plus personnelle et de vous éviter
                            d'avoir à ressaisir vos préférences chaque fois que
                            vous utilisez le site Web.
                        </ListItem>
                    </List>
                    <Typography variant="body1" gutterBottom>
                        Pour plus d'informations sur les cookies que nous
                        utilisons et vos choix concernant les cookies, veuillez
                        visiter notre Politique de cookies ou la section Cookies
                        de notre Politique de confidentialité.
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Utilisation de vos données personnelles
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        La Société peut utiliser les données personnelles aux
                        fins suivantes:
                    </Typography>
                    <List>
                        <ListItem>
                            {" "}
                            Pour fournir et maintenir notre service, y compris
                            pour surveiller l'utilisation de notre service.
                        </ListItem>
                        <ListItem>
                            {" "}
                            Pour gérer votre compte: pour gérer votre
                            inscription en tant qu'utilisateur du service. Les
                            données personnelles que vous fournissez peuvent
                            vous donner accès à différentes fonctionnalités du
                            service qui vous sont disponibles en tant
                            qu'utilisateur enregistré.
                        </ListItem>
                        <ListItem>
                            {" "}
                            Pour l'exécution d'un contrat: le développement, la
                            conformité et l'engagement du contrat d'achat pour
                            les produits, articles ou services que vous avez
                            achetés ou de tout autre contrat avec nous via le
                            service.
                        </ListItem>
                        <ListItem>
                            {" "}
                            Pour vous contacter: Pour vous contacter par e-mail,
                            appels téléphoniques, SMS ou autres formes
                            équivalentes de communication électronique, telles
                            que les notifications push d'une application mobile
                            concernant les mises à jour ou les communications
                            informatives liées aux fonctionnalités, produits ou
                            services sous contrat, y compris les mises à jour de
                            sécurité, lorsque cela est nécessaire ou raisonnable
                            pour leur mise en œuvre.
                        </ListItem>
                        <ListItem>
                            {" "}
                            Pour vous fournir des nouvelles, des offres
                            spéciales et des informations générales sur d'autres
                            biens, services et événements que nous proposons et
                            similaires à ceux que vous avez déjà achetés ou sur
                            lesquels vous vous êtes renseigné, à moins que vous
                            n'ayez choisi de ne pas recevoir ces informations.
                        </ListItem>
                        <ListItem>
                            {" "}
                            Pour gérer vos demandes: pour assister et gérer vos
                            demandes à nous.
                        </ListItem>
                        <ListItem>
                            {" "}
                            Pour les transferts d'entreprise: Nous pouvons
                            utiliser vos informations pour évaluer ou mener une
                            fusion, un désinvestissement, une restructuration,
                            une réorganisation, une dissolution ou toute autre
                            vente ou transfert de tout ou partie de nos actifs,
                            que ce soit dans le cadre d'une exploitation en
                            cours ou dans le cadre d'une faillite, liquidation,
                            ou une procédure similaire, dans laquelle les
                            données personnelles que nous détenons sur les
                            utilisateurs de nos services font partie des actifs
                            transférés.
                        </ListItem>
                        <ListItem>
                            {" "}
                            À d'autres fins: Nous pouvons utiliser vos
                            informations à d'autres fins, telles que l'analyse
                            des données, l'identification des tendances
                            d'utilisation, la détermination de l'efficacité de
                            nos campagnes promotionnelles et pour évaluer et
                            améliorer notre service, nos produits, nos services,
                            notre marketing et votre expérience.
                        </ListItem>
                    </List>
                    <Typography>
                        {" "}
                        Nous pouvons partager vos informations personnelles dans
                        les situations suivantes:
                    </Typography>
                    <List>
                        <ListItem>
                            {" "}
                            Avec les fournisseurs de services: nous pouvons
                            partager vos informations personnelles avec les
                            fournisseurs de services pour surveiller et analyser
                            l'utilisation de notre service, pour vous contacter.
                        </ListItem>
                        <ListItem>
                            {" "}
                            Pour les transferts d'entreprise: Nous pouvons
                            partager ou transférer vos informations personnelles
                            dans le cadre ou pendant les négociations de toute
                            fusion, vente d'actifs de la société, financement ou
                            acquisition de tout ou partie de nos activités à une
                            autre société.
                        </ListItem>
                        <ListItem>
                            {" "}
                            Avec les affiliés: nous pouvons partager vos
                            informations avec nos affiliés, auquel cas nous
                            demanderons à ces affiliés d'honorer cette politique
                            de confidentialité. Les affiliés comprennent notre
                            société mère et toutes autres filiales, partenaires
                            de coentreprise ou autres sociétés que nous
                            contrôlons ou qui sont sous contrôle commun avec
                            nous.
                        </ListItem>
                        <ListItem>
                            {" "}
                            Avec des partenaires commerciaux: Nous pouvons
                            partager vos informations avec nos partenaires
                            commerciaux pour vous proposer certains produits,
                            services ou promotions.
                        </ListItem>
                        <ListItem>
                            {" "}
                            Avec d'autres utilisateurs: lorsque vous partagez
                            des informations personnelles ou interagissez d'une
                            autre manière dans les zones publiques avec d'autres
                            utilisateurs, ces informations peuvent être vues par
                            tous les utilisateurs et peuvent être diffusées
                            publiquement à l'extérieur. Si vous interagissez
                            avec d'autres utilisateurs ou si vous vous inscrivez
                            via un service de médias sociaux tiers, vos contacts
                            sur le service de médias sociaux tiers peuvent voir
                            votre nom, votre profil, vos photos et la
                            description de votre activité. De même, d'autres
                            utilisateurs pourront voir les descriptions de votre
                            activité, communiquer avec vous et afficher votre
                            profil.
                        </ListItem>
                        <ListItem>
                            {" "}
                            Avec votre consentement: nous pouvons divulguer vos
                            informations personnelles à toute autre fin avec
                            votre consentement.
                        </ListItem>
                    </List>
                    <Typography variant="h6" component="h2" gutterBottom>
                        {" "}
                        Conservation de vos données personnelles
                    </Typography>
                    <Typography>
                        La Société ne conservera vos données personnelles que le
                        temps nécessaire aux fins énoncées dans la présente
                        politique de confidentialité. Nous conserverons et
                        utiliserons vos données personnelles dans la mesure
                        nécessaire pour nous conformer à nos obligations légales
                        (par exemple, si nous sommes tenus de conserver vos
                        données pour nous conformer aux lois applicables),
                        résoudre les litiges et appliquer nos accords et
                        politiques juridiques. La Société conservera également
                        les données d'utilisation à des fins d'analyse interne.
                        Les données d'utilisation sont généralement conservées
                        pendant une période plus courte, sauf lorsque ces
                        données sont utilisées pour renforcer la sécurité ou
                        pour améliorer la fonctionnalité de notre service, ou
                        nous sommes légalement obligés de conserver ces données
                        pendant des périodes plus longues.
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        {" "}
                        Transfert de vos données personnelles
                    </Typography>
                    <Typography>
                        Vos informations, y compris les données personnelles,
                        sont traitées dans les bureaux d'exploitation de la
                        société et dans tout autre endroit où se trouvent les
                        parties impliquées dans le traitement. Cela signifie que
                        ces informations peuvent être transférées vers - et
                        conservées sur - des ordinateurs situés en dehors de
                        votre état, province, pays ou autre juridiction
                        gouvernementale où les lois sur la protection des
                        données peuvent différer de celles de votre juridiction.
                        Votre consentement à cette politique de confidentialité
                        suivi de votre soumission de ces informations représente
                        votre accord à ce transfert. La Société prendra toutes
                        les mesures raisonnablement nécessaires pour garantir
                        que vos données sont traitées en toute sécurité et
                        conformément à la présente politique de confidentialité
                        et aucun transfert de vos données personnelles n'aura
                        lieu à un organisat
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        {" "}
                        Divulgation de vos données personnelles
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        {" "}
                        Transactions commerciales
                    </Typography>
                    <Typography>
                        {" "}
                        Si la société est impliquée dans une fusion, une
                        acquisition ou une vente d'actifs, vos données
                        personnelles peuvent être transférées. Nous vous
                        informerons avant que vos données personnelles ne soient
                        transférées et deviennent soumises à une politique de
                        confidentialité différente.
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        {" "}
                        Forces de l'ordre
                    </Typography>
                    <Typography>
                        {" "}
                        Dans certaines circonstances, la Société peut être tenue
                        de divulguer vos données personnelles si la loi l'exige
                        ou en réponse à des demandes valides des autorités
                        publiques (par exemple, un tribunal ou une agence
                        gouvernementale).
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        Autres exigences légales{" "}
                    </Typography>
                    <Typography>
                        {" "}
                        La Société peut divulguer vos données personnelles en
                        croyant de bonne foi qu'une telle action est nécessaire
                        pour:
                    </Typography>
                    <List>
                        <ListItem>
                            Se conformer à une obligation légale
                        </ListItem>
                        <ListItem>
                            {" "}
                            Protéger et défendre les droits ou la propriété de
                            l'entreprise
                        </ListItem>
                        <ListItem>
                            {" "}
                            Prévenir ou enquêter sur d'éventuels actes
                            répréhensibles en relation avec le Service
                        </ListItem>
                        <ListItem>
                            {" "}
                            Protéger la sécurité personnelle des Utilisateurs du
                            Service ou du public
                        </ListItem>
                        <ListItem>
                            {" "}
                            Protéger contre la responsabilité légale
                        </ListItem>
                    </List>
                    <Typography variant="h6" component="h3" gutterBottom>
                        {" "}
                        Sécurité de vos données personnelles
                    </Typography>
                    <Typography>
                        {" "}
                        La sécurité de vos données personnelles est importante
                        pour nous, mais rappelez-vous qu'aucune méthode de
                        transmission sur Internet ou méthode de stockage
                        électronique n'est sécurisée à 100%. Bien que nous nous
                        efforcions d'utiliser des moyens commercialement
                        acceptables pour protéger vos données personnelles, nous
                        ne pouvons garantir leur sécurité absolue. Informations
                        détaillées sur le traitement de vos données personnelles
                        Les fournisseurs de services que nous utilisons peuvent
                        avoir accès à vos données personnelles. Ces fournisseurs
                        tiers collectent, stockent, utilisent, traitent et
                        transfèrent des informations sur votre activité sur
                        notre service conformément à leurs politiques de
                        confidentialité.
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        {" "}
                        Utilisation, performances et divers
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        {" "}
                        Nous pouvons utiliser des fournisseurs de services tiers
                        pour améliorer notre service.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {" "}
                        Google Places est un service qui renvoie des
                        informations sur les lieux à l'aide de requêtes HTTP. Il
                        est exploité par Google Le service Google Places peut
                        collecter des informations auprès de vous et de votre
                        appareil à des fins de sécurité. Les informations
                        recueillies par Google Places sont conservées
                        conformément à la politique de confidentialité de
                        Google: https://www.google.com/intl/en/policies/privacy/
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        {" "}
                        Liens vers d'autres sites Web
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {" "}
                        Notre service peut contenir des liens vers d'autres
                        sites Web qui ne sont pas exploités par nous. Si vous
                        cliquez sur un lien tiers, vous serez dirigé vers le
                        site de ce tiers. Nous vous conseillons vivement de
                        consulter la politique de confidentialité de chaque site
                        que vous visitez. Nous n'avons aucun contrôle sur et
                        n'assumons aucune responsabilité pour le contenu, les
                        politiques de confidentialité ou les pratiques de tout
                        site ou service tiers.
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        Modifications de cette politique de confidentialité
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {" "}
                        Nous pouvons mettre à jour notre politique de
                        confidentialité de temps à autre. Nous vous informerons
                        de tout changement en publiant la nouvelle politique de
                        confidentialité sur cette page. Nous vous informerons
                        par e-mail et / ou par un avis visible sur notre
                        service, avant que le changement ne devienne effectif,
                        et nous mettrons à jour la date de «dernière mise à
                        jour» en haut de cette politique de confidentialité. Il
                        vous est conseillé de consulter périodiquement cette
                        politique de confidentialité pour tout changement. Les
                        modifications apportées à cette politique de
                        confidentialité sont effectives lorsqu'elles sont
                        publiées sur cette page.
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        Nous contacter
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {" "}
                        Si vous avez des questions sur cette politique de
                        confidentialité, vous pouvez nous contacter: En visitant
                        cette page sur notre site: https://soinsdom.fr/contact
                    </Typography>
                </div>
            </div>
        </Box>
    );
};
export default Privacy;
