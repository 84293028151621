import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Alert from "@mui/lab/Alert";
import React, { useState } from "react";
import I18n from "../../../config/i18n/I18n";
import classes from "./SmsComponent.module.scss";
import { phonePattern } from "../../../config/regExpPatterns";
import Professional from "../../../model/Professional.model";
import environment from "../../../config/environment";
import { useForm } from "react-hook-form";

type Props = {
    open: boolean;
    confirm: (phone: string) => void;
    closeDialog: () => void;
};

const SmsComponent = (props: Props) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Professional>();
    const [phone, setPhone] = useState<string>("");
    const onSubmit = () => {
        props.confirm(phone);
    };
    return (
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            onClose={props.closeDialog}
        >
            <DialogTitle id="alert-dialog-title">
                {I18n.smsSentPhoneNumber}
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent
                    classes={{ root: classes.dialogContentContainer }}
                >
                    <TextField
                        error={errors.phone !== undefined}
                        type="text"
                        label={I18n.mobile}
                        placeholder={I18n.mobilePlaceholder}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        variant="outlined"
                        {...register(
                            "phone",
                            environment.production
                                ? {
                                      required: true,
                                      maxLength: 10,
                                      pattern: {
                                          value: phonePattern,
                                          message: "invalid phone number",
                                      },
                                      onChange: (event) =>
                                          setPhone(event.target.value),
                                  }
                                : {
                                      required: true,
                                      onChange: (event) =>
                                          setPhone(event.target.value),
                                  }
                        )}
                    />
                    <br />
                    {errors.phone ? (
                        <Alert severity="error">
                            {I18n.invalidCredentials}
                        </Alert>
                    ) : null}
                </DialogContent>
                <DialogActions
                    classes={{ root: classes.dialogActionContainer }}
                >
                    <Button type="submit" variant="outlined" color="primary">
                        {I18n.send}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
export default SmsComponent;
