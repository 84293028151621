import React, { useEffect, useState } from "react";
import classes from "./RdvRequestStep.module.scss";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import I18n from "../../../config/i18n/I18n";
import Autocomplete from "@mui/lab/Autocomplete";
import { Request } from "../../../model/Appointment.model";
import { useForm } from "react-hook-form";
import Alert from "@mui/lab/Alert";
import CareWebService from "../../../ws/care/Care.ws";
import CareType from "../../../model/CareType.model";
import { Box, Checkbox, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
type rdvRequestStepPropsType = {
    createAppointment: VoidFunction;
    request: (arg1: string, arg2: any) => void;
};

const RdvRequestStep: React.FC<rdvRequestStepPropsType> = (
    props: rdvRequestStepPropsType
): React.ReactElement => {
    const [conditionAccepted, setConditionAccepted] = useState({
        isAccepted: false,
        showAlert: false,
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Request>();

    const [careTypesArray, setCareTypesArray] = useState<Array<CareType>>([]);
    const autoCompleteChange = (element: any, value: any) => {
        console.log(value);
        props.request("careType", value);
    };
    useEffect(() => {
        CareWebService.getCareTypes().then((resp) => {
            setCareTypesArray(resp.data);
        });
    }, []);
    /**
     * Validation checkbox conditions
     */
    const validateConditions = () => {
        if (!conditionAccepted.isAccepted) {
            setConditionAccepted({ ...conditionAccepted, showAlert: true });
        } else {
            //Send an appointment object to the server
            props.createAppointment();
        }
    };
    const onSubmit = (data: any) => {
        validateConditions();
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Stack gap={1}>
                <Autocomplete
                    id="size-small-standard"
                    size="small"
                    options={careTypesArray}
                    getOptionLabel={(option) =>
                        option && option.name ? String(option.name) : ""
                    }
                    onChange={autoCompleteChange}
                    renderInput={(params) => (
                        <TextField
                            error={errors.careType !== undefined}
                            {...register("careType", { required: true })}
                            {...params}
                            variant="outlined"
                            label={I18n.careType}
                            placeholder="Favorites"
                        />
                    )}
                />
                <TextField
                    error={errors.appointmentDate !== undefined}
                    id="date"
                    label={I18n.appointmentDate}
                    {...register("appointmentDate", { required: true })}
                    onChange={(event) =>
                        props.request("appointmentDate", event.target.value)
                    }
                    type="date"
                    variant="outlined"
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                {errors.careType && (
                    <Alert severity="error">{I18n.requiredProperties}</Alert>
                )}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Link to="conditions">
                        <Typography variant="body1" gutterBottom>
                            {I18n.agreeToConditions}
                        </Typography>
                    </Link>
                    <Checkbox
                        color="primary"
                        defaultChecked={false}
                        onChange={(event) =>
                            setConditionAccepted({
                                ...conditionAccepted,
                                isAccepted: event.target.checked,
                            })
                        }
                        inputProps={{ "aria-label": "primary checkbox" }}
                    />
                </Box>
                <Button type="submit" variant="outlined" color="primary">
                    {I18n.getAppointment}
                </Button>
                {conditionAccepted.showAlert && (
                    <Alert severity="error">
                        {I18n.agreeToConditionsAlert}
                    </Alert>
                )}
            </Stack>
        </form>
    );
};
export default RdvRequestStep;
