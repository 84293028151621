import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import classes from './DataLoading.module.scss';
import Typography from '@mui/material/Typography';

type Props={
    message:string
}
const DataLoading = (props:Props) => {
    return (
        <div className={classes.ProgressContainer}>
            <Typography variant="h5"  gutterBottom>{props.message}</Typography>
            <CircularProgress />
        </div>
    )
}
export default DataLoading;