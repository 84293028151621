import axios from "../../config/axios";
import Professional from "../../model/Professional.model";
import PATH, { urlBuilder } from "../../config/path";
import ResponseEntity from "../../model/ResponseEntity";
import AppointmentDto from "../appointment/AppointmentDto";

export default class ProfessionalWebService {
    public static createProfessional(
        professional: Professional
    ): Promise<ResponseEntity<Professional>> {
        return axios.post(PATH.professional.empty, professional);
    }
    public static getProfessional(
        userId: string
    ): Promise<ResponseEntity<Professional>> {
        return axios.get(PATH.professional.empty + `/${userId}`);
    }

    public static getPendingAppointment(
        userId: string
    ): Promise<ResponseEntity<Array<AppointmentDto>>> {
        return axios.get(
            urlBuilder(
                PATH.professional.empty,
                "/",
                userId,
                "/",
                PATH.professional.pending
            )
        );
    }
    public static getAppointment(
        userId: string
    ): Promise<ResponseEntity<Array<AppointmentDto>>> {
        return axios.get(
            urlBuilder(
                PATH.professional.empty,
                "/",
                userId,
                "/",
                PATH.professional.appointments
            )
        );
    }
    public static updateProfessionalProfile(
        professional: Professional
    ): Promise<ResponseEntity<Professional>> {
        return axios.put(PATH.professional.empty, professional);
    }
    public static updateProfessionalPhone(
        userEmail: string,
        professional: Partial<Professional>
    ): Promise<ResponseEntity<string>> {
        return axios.put(
            `${PATH.professional.empty}/${userEmail}/${PATH.professional.phone}`,
            professional
        );
    }
}
