import React, { useState } from "react";
import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import AuthWebService from "../../../ws/auth/Auth.ws";
import I18n from "../../../config/i18n/I18n";
import TextField from "@mui/material/TextField";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import classes from "./optDialog.module.scss";
import Alert from "@mui/lab/Alert";
import AppointmentWebService from "../../../ws/appointment/Appointment.ws";

type Props = {
    isAppointmentConfirmation: boolean;
    open: boolean;
    confirm: () => void;
    phone: string;
    closeDialog: () => void;
    displayNetworkAlert: () => void;
    userId?: string;
    appointmentId?: string;
};

function OtpDialog(props: Props) {
    const [otp, setOtp] = useState<string>("");
    const [validOtp, setValidOtp] = useState<boolean>(true);
    const verifyOtp = () => {
        if (props.isAppointmentConfirmation) {
            AppointmentWebService.validateAppointment(
                String(props.appointmentId),
                otp
            ).then(
                (resp) => {
                    if (resp.data === true) {
                        setValidOtp(true);
                        props.confirm();
                        props.closeDialog();
                    } else {
                        setValidOtp(false);
                    }
                },
                (error) => {
                    props.displayNetworkAlert();
                }
            );
        } else {
            AuthWebService.activateAccount(String(props.userId), otp).then(
                (resp) => {
                    if (resp.data === true) {
                        setValidOtp(true);
                        props.confirm();
                        props.closeDialog();
                    } else {
                        setValidOtp(false);
                    }
                },
                (error) => {
                    props.displayNetworkAlert();
                }
            );
        }
    };
    return (
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            onClose={props.closeDialog}
        >
            <DialogTitle id="alert-dialog-title">
                {I18n.smsSent + props.phone}
            </DialogTitle>

            <form>
                <DialogContent
                    classes={{ root: classes.dialogContentContainer }}
                >
                    <TextField
                        type="number"
                        label={I18n.otp}
                        variant="outlined"
                        onChange={(event) => setOtp(event.target.value)}
                    />
                    <br />
                    {validOtp ? null : (
                        <Alert severity="error">{I18n.invalidOtp}</Alert>
                    )}
                </DialogContent>
                <DialogActions
                    classes={{ root: classes.dialogActionContainer }}
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={verifyOtp}
                    >
                        {I18n.send}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default OtpDialog;
