import axios from '../../config/axios';
import PATH, { urlBuilder } from '../../config/path';
import Appointment from '../../model/Appointment.model';
import ResponseEntity from '../../model/ResponseEntity';
import AppointmentDto from './AppointmentDto';

export default class AppointmentWebService {
    public static createAppointment(appointment: Appointment): Promise<ResponseEntity<AppointmentDto>> {
        return axios.post(PATH.appointment.empty, AppointmentDto.convertAppointmentToDto(appointment));
    }
    public static getAppointments(userId: string): Promise<ResponseEntity<Array<AppointmentDto>>> {
        return axios.get(PATH.appointment.empty, { params: { userId: userId } });
    }
    public static confirmAppointment(appointmentId: string, userId: string): Promise<ResponseEntity<Boolean>> {
        return axios.post(urlBuilder(PATH.appointment.empty, PATH.appointment.confirm), null, { params: { appointmentId, userId } })
    }
    public static rejectAppointment(appointmentId: string, userId: string): Promise<ResponseEntity<Boolean>> {
        return axios.post(urlBuilder(PATH.appointment.empty, PATH.appointment.reject), null, { params: { appointmentId, userId } })
    }
    public static validateAppointment(appointmentId: string, otp: string): Promise<ResponseEntity<Boolean>> {
        return axios.put(`${PATH.appointment.empty}/${PATH.appointment.validate}`, null, {
            params: {
                appointmentId, otp
            }
        })
    }
}