import axios from '../../config/axios';
import User from '../../model/User.model';
import PATH, { urlBuilder } from '../../config/path';
import ResponseEntity from '../../model/ResponseEntity';

export default class AuthWebService {

    public static signIn(user: User): Promise<ResponseEntity<User>> {
        return axios.post(urlBuilder(PATH.auth.empty, PATH.auth.signIn), user);
    }
    public static updatePassword(user: User): Promise<ResponseEntity<User>> {
        return axios.post(urlBuilder(PATH.auth.empty, PATH.auth.updatePassword), user);
    }
    public static isPasswordValid(user: User): Promise<ResponseEntity<Boolean>> {
        return axios.post(urlBuilder(PATH.auth.empty, PATH.auth.checkPassword), user);
    }
    public static activateAccount(userId: string, otp: string): Promise<ResponseEntity<Boolean>> {
        return axios.put(`${PATH.auth.empty}/${PATH.auth.activate}`, null, {
            params: {
                userId,
                otp
            }
        });
    }
}
