import Button from "@mui/material/Button/Button";
import ButtonGroup from "@mui/material/ButtonGroup/ButtonGroup";
import TableCell from "@mui/material/TableCell/TableCell";
import TableRow from "@mui/material/TableRow/TableRow";
import React, { useReducer, useState } from "react";
import I18n from "../../../../../config/i18n/I18n";
import LocalStorageUtil from "../../../../../utils/localStorageUtil";
import AppointmentWebService from "../../../../../ws/appointment/Appointment.ws";
import AlertDialog from "../../../../ui/alertDialog/AlertDialog";
import { IconButton } from "@mui/material";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import RdvDetailsDialog from "../../../../appointment/rdvDetailsDialog/RdvDetailsDialog";
import AppointmentDto from "../../../../../ws/appointment/AppointmentDto";
type Props = {
    id: string;
    fullName: string;
    date: string;
    appointment: AppointmentDto;
    appointmentCallHandler: (appointmentId: string) => void;
};
const RdvQueueRow = (props: Props) => {
    const [openRdvDetails, setOpenRdvDetails] = useReducer(
        (state: boolean) => !state,
        false
    );

    const onAcceptClicked = () => {
        AppointmentWebService.confirmAppointment(
            props.id,
            String(LocalStorageUtil.getUserId())
        ).then(
            (resp) => {
                setConfirmationModel(true);
                props.appointmentCallHandler(props.id);
            },
            (error) => {
                setOpenNetworkAlert(true);
            }
        );
    };
    const onRejectClicked = () => {
        AppointmentWebService.rejectAppointment(
            props.id,
            String(LocalStorageUtil.getUserId())
        ).then(
            (resp) => {
                setConfirmationModel(true);
                props.appointmentCallHandler(props.id);
            },
            (error) => {
                setOpenNetworkAlert(true);
            }
        );
    };
    const [openNetworkAlert, setOpenNetworkAlert] = useState<boolean>(false);
    /**
     * Close alert handler
     */
    const closeNetworkAlertHandler = () => {
        setOpenNetworkAlert(false);
    };
    const [confirmationModel, setConfirmationModel] = useState<boolean>(false);
    /**
     * Close alert handler
     */
    const closeConfirmationAlertHandler = () => {
        setConfirmationModel(false);
    };
    return (
        <>
            {" "}
            <AlertDialog
                open={openNetworkAlert}
                onClick={closeNetworkAlertHandler}
                title={I18n.networkErrorTitle}
                content={I18n.networkErrorContent}
            />
            <AlertDialog
                open={confirmationModel}
                onClick={closeConfirmationAlertHandler}
                title={I18n.appointmentTreatedTitle}
                content={I18n.appointmentTreatedContent}
            />
            <RdvDetailsDialog
                open={openRdvDetails}
                handleClose={setOpenRdvDetails}
                appointment={props.appointment}
            />
            <TableRow hover role="checkbox" tabIndex={-1}>
                <TableCell align="center">{props.id}</TableCell>
                <TableCell align="center">{props.fullName}</TableCell>
                <TableCell align="center">{props.date}</TableCell>
                <TableCell align="center">
                    <IconButton onClick={setOpenRdvDetails}>
                        <MenuBookIcon />
                    </IconButton>
                </TableCell>
                <TableCell align="center">
                    <ButtonGroup
                        disableElevation
                        variant="contained"
                        color="primary"
                    >
                        <Button variant="outlined" onClick={onAcceptClicked}>
                            {I18n.accept}
                        </Button>
                        <Button variant="outlined" onClick={onRejectClicked}>
                            {I18n.reject}
                        </Button>
                    </ButtonGroup>
                </TableCell>
            </TableRow>
        </>
    );
};
export default RdvQueueRow;
