import { Button, TextField } from "@mui/material";
import Alert from "@mui/lab/Alert/Alert";
import React, { useContext, useReducer, useState } from "react";
import I18n from "../../../config/i18n/I18n";
import classes from "./ProfessionalSignIn.module.scss";
import { useForm } from "react-hook-form";
import User from "../../../model/User.model";
import AuthWebService from "../../../ws/auth/Auth.ws";
import ProfessionalWebService from "../../../ws/professional/Professional.ws";
import { useHistory } from "react-router-dom";
import AlertDialog from "../../ui/alertDialog/AlertDialog";
import { AuthContext } from "../../../App";
import OtpDialog from "../../ui/otpDialog/otpDialog";
import SmsComponent from "../../ui/smsComponent/SmsComponent";
import axios from "../../../config/axios";
type Props = {};
const ProfessionalSignIn: React.FC<Props> = (props: Props) => {
    /**
     * Otp modal
     */
    const [openSmsModal, setOpenSmsModal] = useReducer(
        (openSmsModal) => !openSmsModal,
        false
    );
    /**
     * Otp modal
     */
    const [openOtpModal, setOpenOtpModal] = useReducer(
        (openOtpModal) => !openOtpModal,
        false
    );
    const [openAlert, openAlertHandler] = useReducer(
        (openAlert: boolean) => !openAlert,
        false
    );
    const context = useContext(AuthContext);
    const [user, setUser] = useState({
        email: "",
        password: "",
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<User>();
    const router = useHistory();
    const [validCredentials, setValidCredentials] = useState(true);
    const [openNetworkAlert, setOpenNetworkAlert] = useState<boolean>(false);
    const [otpState, setOtpState] = useState({ phone: "", userId: "" });
    /**
     * Close alert handler
     */
    const closeNetworkAlertHandler = () => {
        setOpenNetworkAlert(false);
    };
    /**
     * Submit form handler
     */
    const onSubmit = () => {
        AuthWebService.signIn(user)
            .then((resp) => {
                if (resp.status === 204) {
                    openAlertHandler();
                } else {
                    const token = resp.headers.authorization;
                    localStorage.setItem("user", JSON.stringify(resp.data));
                    if (context) {
                        context.setIsConnected(true);
                    }
                    localStorage.setItem("isConnected", JSON.stringify(true));
                    localStorage.setItem("token", token);
                    axios.defaults.headers.common = {
                        Authorization: `Bearer ${token}`,
                    };
                    router.push("dashboard");
                }
            })
            .catch((error) => {
                setValidCredentials(false);
            });
    };
    /**
     * Close opt dialog
     */
    const closeOtpDialog = () => {
        setOpenOtpModal();
    };
    /**
     * Close opt dialog
     */
    const closeSmsDialog = () => {
        setOpenSmsModal();
    };
    /**
     * When otp dialog confirmed
     */
    const onOtpConfirm = () => {};
    /**
     * When otp dialog confirmed
     */
    const onSmsConfirm = (phone: any) => {
        ProfessionalWebService.updateProfessionalPhone(user.email, {
            phone,
        })
            .then((resp) => {
                setOtpState({ phone, userId: resp.data });
                setOpenSmsModal();
                setOpenOtpModal();
            })
            .catch((err) => displayNetworkAlert());
    };
    /**
     * Display network error alert
     */
    const displayNetworkAlert = () => {
        setOpenNetworkAlert(true);
    };
    const onCloseConfirmAccountAlert = () => {
        openAlertHandler();
        setOpenSmsModal();
    };
    return (
        <>
            <OtpDialog
                isAppointmentConfirmation={false}
                open={openOtpModal}
                phone={otpState.phone}
                closeDialog={closeOtpDialog}
                displayNetworkAlert={displayNetworkAlert}
                userId={otpState.userId}
                confirm={onOtpConfirm}
            />
            <SmsComponent
                open={openSmsModal}
                closeDialog={closeSmsDialog}
                confirm={onSmsConfirm}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.ProfessionalSignInstepContainer}>
                    <AlertDialog
                        open={openAlert}
                        onClick={onCloseConfirmAccountAlert}
                        title={I18n.disabledAccount}
                        content={I18n.disabledAccountMessage}
                    />
                    <AlertDialog
                        open={openNetworkAlert}
                        onClick={closeNetworkAlertHandler}
                        title={I18n.networkErrorTitle}
                        content={I18n.networkErrorContent}
                    />
                    <TextField
                        label={I18n.email}
                        variant="outlined"
                        type="email"
                        error={errors.email !== undefined}
                        {...register("email", {
                            required: true,
                            onChange: (event) =>
                                setUser({ ...user, email: event.target.value }),
                        })}
                    />
                    <TextField
                        type="password"
                        label={I18n.password}
                        variant="outlined"
                        error={errors.password !== undefined}
                        {...register("password", {
                            required: true,
                            onChange: (event) =>
                                setUser({
                                    ...user,
                                    password: event.target.value,
                                }),
                        })}
                    />
                    <Button variant="outlined" color="primary" type="submit">
                        {I18n.signIn}
                    </Button>
                    <br />
                    {(errors.email || errors.password) && (
                        <Alert severity="error">
                            {I18n.requiredProperties}
                        </Alert>
                    )}
                    {!validCredentials ? (
                        <Alert severity="error">
                            {I18n.invalidCredentials}
                        </Alert>
                    ) : null}
                </div>
            </form>
        </>
    );
};

export default ProfessionalSignIn;
