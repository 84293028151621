import React, { useReducer, useState } from "react";
import classes from "./Professional.module.scss";
import PageTitle from "../../features/ui/pageTitle/PageTitle";
import I18n from "../../config/i18n/I18n";
import ProfessionalInformationStep from "../../features/professional/professionalInformationStep/ProfessionalInformationStep";
import ProfessionalSignUp from "../../features/professional/professionalSignUp/ProfessionalSignUp";
import ProfessionalModel from "../../model/Professional.model";
import Civility from "../../model/Civility.enum";
import ProfessionalWebService from "../../ws/professional/Professional.ws";
import { Box, Button } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import withHistory from "../../utils/hoc/HistoryHoc";
import { useHistory } from "react-router-dom";
import AlertDialog from "../../features/ui/alertDialog/AlertDialog";
import OtpDialog from "../../features/ui/otpDialog/otpDialog";
import SoinsdomStepper from "../../features/ui/soinsdomStepper/SoinsdomStepper";
import AuthWebService from "../../ws/auth/Auth.ws";
import { Position } from "../../model/Appointment.model";

type Props = {};
const Professional = (props: Props) => {
    const router = useHistory();

    const [openEmailAlert, openEmailAlertHandler] = useReducer(
        (openEmailAlert: boolean) => !openAlert,
        false
    );
    /**
     * SignedUp userId
     */
    const [userId, setUserId] = useState<string>("");
    /**
     * Otp modal
     */
    const [openOtpModal, setOpenOtpModal] = useState<boolean>(false);
    const [professional, setProfessional] = useState<ProfessionalModel>({
        numAdeli: "",
        firstName: "",
        lastName: "",
        civility: Civility.Mr,
        phone: "",
        officeNumber: "",
        type: { id: 0, name: "" },
        address: "",
        zipCode: "",
        city: "",
        email: "",
        password: "",
        lat: "",
        lng: "",
    });
    /**
     * Update professional state
     */
    const updateProfessional = (key: string, value: any) => {
        setProfessional((professional) => ({
            ...professional,
            [key]: value,
        }));
    };
    /**
     * Update the rdv position state
     */
    const updateProfessionalPositionState = (position: Position) => {
        setProfessional((professional) => ({
            ...professional,
            ...position,
        }));
    };
    /**
     * Create a professional profile
     */
    const createProfessional = () => {
        return ProfessionalWebService.createProfessional(professional);
    };
    /**
     * Create a professional Account
     */
    const signUp = () => {
        createProfessional().then(
            (resp) => {
                if (resp.status === 422) {
                    openEmailAlertHandler();
                } else {
                    if (process.env.REACT_APP_IS_SMS_ENABLED) {
                        setUserId(String(resp.data.id));
                        setOpenOtpModal(true);
                    } else {
                        AuthWebService.activateAccount(
                            String(String(resp.data.id)),
                            "0000"
                        ).then(
                            (resp) => {
                                if (resp.data === true) {
                                    onOtpConfirm();
                                } else {
                                    displayNetworkAlert();
                                }
                            },
                            (error) => {
                                displayNetworkAlert();
                            }
                        );
                    }
                }
            },
            (error) => {
                setOpenNetworkAlert(true);
            }
        );
    };
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    /**
     * Close alert handler
     */
    const closeAlertHandler = () => {
        setOpenAlert(false);
        router.push("sign-in");
    };
    const [activeStep, setActiveStep] = useState(0);
    /**
     * Move forward to the next step
     */
    const nextStep = () => {
        setActiveStep((prevStep: number) => prevStep + 1);
    };
    const [openNetworkAlert, setOpenNetworkAlert] = useState<boolean>(false);
    /**
     * Close alert handler
     */
    const closeNetworkAlertHandler = () => {
        setOpenNetworkAlert(false);
    };
    /**
     * Close opt dialog
     */
    const closeOtpDialog = () => {
        setOpenOtpModal(false);
    };
    /**
     * Display network error alert
     */
    const displayNetworkAlert = () => {
        setOpenNetworkAlert(true);
    };
    /**
     * When otp dialog confirmed
     */
    const onOtpConfirm = () => {
        setOpenAlert(true);
    };
    return (
        <Box sx={{ padding: { xs: 2, md: 10 } }}>
            <OtpDialog
                isAppointmentConfirmation={false}
                open={openOtpModal}
                phone={professional.phone}
                closeDialog={closeOtpDialog}
                displayNetworkAlert={displayNetworkAlert}
                userId={userId}
                confirm={onOtpConfirm}
            />
            <AlertDialog
                open={openEmailAlert}
                onClick={openEmailAlertHandler}
                title={I18n.emailExists}
                content={I18n.emailExistsMessage}
            />
            <AlertDialog
                open={openAlert}
                onClick={closeAlertHandler}
                title={I18n.professionalAccountCreatedTitle}
                content={I18n.professionalAccountCreatedContent}
            />
            <AlertDialog
                open={openNetworkAlert}
                onClick={closeNetworkAlertHandler}
                title={I18n.networkErrorTitle}
                content={I18n.networkErrorContent}
            />
            <PageTitle title={I18n.professionalPage}>
                <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => router.push("sign-in")}
                >
                    <span className={classes.AlreadySignedUpText}>
                        {I18n.alreadySignedUp}&nbsp;&nbsp;
                    </span>
                    <AccountCircleIcon />
                </Button>
            </PageTitle>
            <Box>
                <SoinsdomStepper
                    activeStep={activeStep}
                    steps={[I18n.myInformation, I18n.signUp]}
                />
                {/** Form  part*/}
                <Box className={classes.professionalStepsContainer}>
                    {activeStep === 0 ? (
                        <ProfessionalInformationStep
                            professional={professional}
                            nextStep={nextStep}
                            updateProfessional={updateProfessional}
                            updateProfessionalPositionState={
                                updateProfessionalPositionState
                            }
                        />
                    ) : null}

                    {/** Appointment information part */}

                    {activeStep === 1 ? (
                        <ProfessionalSignUp
                            nextStep={nextStep}
                            updateProfessional={updateProfessional}
                            signUp={signUp}
                        />
                    ) : null}
                </Box>
            </Box>
            {/** Form part end*/}
        </Box>
    );
};
export default withHistory(Professional);
