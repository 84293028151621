import React from "react";
import classes from "./Landing.module.scss";
import backgroundImage from "../../assets/images/landingbg.jpg";
import LandingCard from "../../features/ui/landingCard/LandingCard";
import appointmentImage from "../../assets/images/appointment.jpg";
import mapImage from "../../assets/images/map.jpg";
import notificationImage from "../../assets/images/notification.png";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import I18n from "../../config/i18n/I18n";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import withHistory from "../../utils/hoc/HistoryHoc";
import { useHistory } from "react-router-dom";
import { Box, Stack } from "@mui/material";

function Landing() {
    const router = useHistory();
    return (
        <>
            <Box
                sx={{
                    backgroundImage: "url(" + backgroundImage + ")",
                }}
                className={classes.landingBackgroundImage}
            >
                <Box className={classes.backgroundImageMask}></Box>

                <div className={classes.landingFirstPart}>
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        color="secondary"
                    >
                        {I18n.getAppointment}
                    </Typography>
                    <Typography
                        gutterBottom
                        variant="h6"
                        component="h2"
                        color="secondary"
                        align="center"
                    >
                        {I18n.findAProfessional}
                    </Typography>
                    <Button
                        variant="contained"
                        size="large"
                        style={{ backgroundColor: "#fb803a", color: "#FFFFFF" }}
                        onClick={() => router.push("rdv")}
                    >
                        {I18n.search}
                    </Button>
                </div>
            </Box>
            <div className={classes.landingShowMoreBtn}>
                <Button variant="outlined" href="#landing-second-part">
                    <ExpandMoreIcon color="secondary" />
                </Button>
            </div>
            <Divider />
            <div className={classes.landingSecondPart} id="landing-second-part">
                <Stack
                    spacing={2}
                    direction={{ xs: "column", lg: "row" }}
                    paddingX={{ xs: "7px", lg: "0" }}
                >
                    <Box sx={{ width: "100%" }}>
                        <LandingCard
                            cardImage={appointmentImage}
                            cardText={`En utilisant notre plateforme vous avez la possibilité de prendre un rendez-vous depuis votre téléphone en quelques étapes.`}
                            cardTitle="Prendre un rendez-vous en ligne"
                        />
                    </Box>
                    <Box sx={{ width: "100%" }}>
                        <LandingCard
                            cardImage={mapImage}
                            cardText={`Votre demande de prise de rendez-vous sera affectée au plus proche professionnel de santé disponible dans votre région.`}
                            cardTitle="Le professionnel le plus proche"
                        />
                    </Box>
                    <Box sx={{ width: "100%" }}>
                        <LandingCard
                            cardImage={notificationImage}
                            cardText={`Vous serez notifiés par SMS qui contient toutes les informations de votre rendez-vous quand un professionnel de santé accepte votre demande.`}
                            cardTitle="Notifications"
                        />
                    </Box>
                </Stack>
            </div>
        </>
    );
}

export default withHistory(Landing);
