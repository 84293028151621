import React, { useState } from "react";
import classes from "./App.module.css";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./features/ui/header/Header";
import Footer from "./features/ui/footer/Footer";
import Divider from "@mui/material/Divider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import RouteModuleComponent from "./router/Router";
import LocalStorageUtil from "./utils/localStorageUtil";
import { Box } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: "#3AB5FB",
        },
        secondary: {
            main: "#FFFFFF",
        },
    },
    typography: {
        fontFamily: "OpenSans",
    },
});
type ContextType = {
    isConnected: boolean;
    setIsConnected: (value: boolean) => void;
};
export const AuthContext = React.createContext<ContextType | undefined>(
    undefined
);
function App() {
    const [isConnected, setIsConnected] = useState(
        LocalStorageUtil.isUserConnected()
    );
    return (
        <ThemeProvider theme={theme}>
            <AuthContext.Provider value={{ isConnected, setIsConnected }}>
                <Router>
                    <Header />
                    <Box sx={{ minHeight: "100vh" }}>
                        <div className={classes.spacingDiv}></div>
                        <RouteModuleComponent />
                        <Divider />
                    </Box>
                    <Footer />
                </Router>
            </AuthContext.Provider>
        </ThemeProvider>
    );
}

export default App;
