import { Autocomplete, TextField } from "@mui/material";
import React, { useState } from "react";
import * as _ from "lodash";
import Places from "../../../ws/google/Places";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import I18n from "../../../config/i18n/I18n";
import { Position } from "../../../model/Appointment.model";
type Props = {
    placeSelected: (position: Position) => void;
    register: UseFormRegister<any>;
    errors: FieldErrors<any>;
};

const PlacesAutoComplete = (props: Props) => {
    const [completeValues, setCompleteValues] = useState<
        google.maps.places.AutocompletePrediction[]
    >([]);
    /**
     * Fires when a suggestion is selected
     * @param element the autocomplete element
     * @param value the selected value
     */
    const onAutoComplete = (element: any, value: any) => {
        const placeDetails: any = _.find(completeValues, (item: any) =>
            _.isEqual(value, item.description)
        );
        if (placeDetails) {
            Places.getPlaceDetails(placeDetails.place_id).then(
                (result: any) => {
                    props.placeSelected({
                        address: value,
                        lat: result.details.geometry.location.lat(),
                        lng: result.details.geometry.location.lng(),
                    });
                }
            );
        }
    };
    /**
     * Get Autocomplete places values from google api
     * @param value
     */
    const displayPlacesSuggestion = (value: string) => {
        if (value.length > 0) {
            Places.getPredictions(value).then((result) => {
                if (result.predictions !== null) {
                    setCompleteValues(result.predictions);
                }
            });
        }
    };
    return (
        <Autocomplete
            onChange={onAutoComplete}
            disableClearable
            options={
                completeValues
                    ? completeValues.map((item: any) => item.description)
                    : []
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={props.errors.address !== undefined}
                    label={I18n.address}
                    {...props.register("address", {
                        required: true,
                        onChange: (elem) =>
                            displayPlacesSuggestion(elem.target.value),
                    })}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        type: "search",
                    }}
                />
            )}
        />
    );
};

export default PlacesAutoComplete;
