import LanguageContract from './LanguageContract';
import French from './French';

class I18n {
    public selectedLanguage:string ='fr';

    static getLanguage(): LanguageContract{
        return French;
    }
}
export default I18n.getLanguage();