import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import I18n from "../../../config/i18n/I18n";
import Button from "@mui/material/Button";
import FacebookIcon from "@mui/icons-material/Facebook";
import IconButton from "@mui/material/IconButton";
import classes from "./Footer.module.scss";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";

type FooterPropsType = {};

const Footer: React.FC<FooterPropsType> = (props: FooterPropsType) => {
    let router = useHistory();

    return (
        <Box width="100%" className={classes.FootRoot}>
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box>
                    <Typography variant="body1" align="center">
                        {I18n.copyright}
                    </Typography>
                </Box>
                <Box>
                    <Button
                        color="secondary"
                        onClick={() => router.push("privacy")}
                    >
                        {I18n.privacy}
                    </Button>
                    <Button
                        color="secondary"
                        onClick={() => router.push("contact")}
                    >
                        {I18n.contactUs}
                    </Button>
                    <IconButton>
                        <FacebookIcon style={{ color: "#006FE7" }} />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};
export default Footer;
