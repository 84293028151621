import React from "react";
import I18n from "../../config/i18n/I18n";
import PageTitle from "../../features/ui/pageTitle/PageTitle";
import Typography from "@mui/material/Typography";
import classes from "./About.module.scss";
import { Box } from "@mui/material";

type Props = {};
const About = (props: Props) => {
    return (
        <Box
            className={classes.AboutContainer}
            sx={{ padding: { xs: 2, md: 10 } }}
        >
            <PageTitle title={I18n.aboutUs} />
            <div className={classes.textContainer}>
                <Typography
                    variant="h6"
                    component="h2"
                    gutterBottom
                    align="center"
                >
                    La plateforme SoinsDom
                </Typography>
                <Typography variant="body1" gutterBottom align="center">
                    <b>SoinsDom </b>est une plateforme de mise en relation entre
                    professionnels de santé et des patients. Notre plateforme
                    facilite aux simples utilisateurs la recherche du plus
                    proche professionnel de santé disponible selon leurs
                    positions géographiques. Pour les professionnels, on fournit
                    un Dashboard pour gérer l'ensemble des rendez-vous ainsi que
                    d'autres services. La plateforme est en cours d'amélioration
                    donc on compte à ajouter plusieurs fonctionnalités afin de
                    faciliter les choses sur les tiers Patients/Professionels
                </Typography>
            </div>
        </Box>
    );
};

export default About;
