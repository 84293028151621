import React from "react";
import Typography from "@mui/material/Typography";
import classes from "./LandingCard.module.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
type landingCardProp = {
    cardTitle: string;
    cardImage: string;
    cardText: string;
};

const LandingCard = (props: landingCardProp) => {
    return (
        <Card className={classes.LandingCardRoot}>
            <CardMedia
                image={props.cardImage}
                title={props.cardTitle}
                sx={{
                    height:{xs:'800px',lg:'400px'}
                }}
            />
            <CardContent sx={{height:"200px"}}>
                <Typography gutterBottom variant="h5" component="h2">
                    {props.cardTitle}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {props.cardText}
                </Typography> 
            </CardContent>
        </Card>
    );
};
export default LandingCard;