import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import React from "react";
type Props = {
    open: boolean;
    title: string;
    content: string;
    onClick: () => void;
};

const AlertDialog = (props: Props) => {
    return (
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={props.onClick}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default AlertDialog;
