import Appointment from "../../model/Appointment.model";
import CareType from "../../model/CareType.model";
import Civility from "../../model/Civility.enum";
import Patient from "../../model/Patient.model";
export default class AppointmentDto {
    id?: string;
    address: string;
    lng: string;
    lat: string;
    careType: CareType;
    appointmentDate: Date;
    patient: Patient;
    constructor(
        address: string,
        lng: string,
        lat: string,
        careType: CareType,
        appointmentDate: Date,
        fullName: string,
        civility: Civility,
        email: string,
        mobile: string,
        birthDate: Date
    ) {
        this.patient = Object.create({});
        this.address = address;
        this.lng = lng;
        this.lat = lat;
        this.careType = careType;
        this.appointmentDate = appointmentDate;
        this.patient.fullName = fullName;
        this.patient.civility = civility;
        this.patient.email = email;
        this.patient.mobile = mobile;
        this.patient.birthDate = birthDate;
    }
    static convertAppointmentToDto(appointment: Appointment): AppointmentDto {
        return new AppointmentDto(
            appointment.position.address,
            appointment.position.lng,
            appointment.position.lat,
            appointment.request.careType,
            appointment.request.appointmentDate,
            appointment.information.fullName,
            appointment.information.civility,
            appointment.information.email,
            appointment.information.mobile,
            appointment.information.birthDate
        );
    }
}
