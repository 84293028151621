const PATH = {
    appointment: {
        empty: "api/appointments",
        confirm: "/confirm",
        reject: "/reject",
        validate: "validate",
    },
    contact: {
        empty: "api/contact",
    },
    auth: {
        empty: "api/users",
        signIn: "/auth",
        updatePassword: "/update-password",
        checkPassword: "/check-password",
        activate: "activate",
    },
    professional: {
        empty: "api/professionals",
        appointments: "appointments",
        pending: "appointments/pending",
        phone: "update-phone",
    },

    professionalTypes: {
        empty: "api/professional-types",
    },
    care: {
        empty: "api/cares",
    },
};
export function urlBuilder(...urls: Array<string>): string {
    let builtUrl = "";
    for (let item of urls) {
        builtUrl += item;
    }
    return builtUrl;
}
export default PATH;
