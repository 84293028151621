import React from 'react'
import { useHistory } from "react-router-dom";

function withHistory (WrappedComponent:any){
    return function(){   
        let history=useHistory();    
            return (
                <WrappedComponent history={history}/>
            )       
    }

}
export default withHistory;