export default class Places {
    private static autoCompleteService =
        new google.maps.places.AutocompleteService();
    private static placeDetailContainer = document.createElement("div");
    private static placesDetailsService = new google.maps.places.PlacesService(
        Places.placeDetailContainer
    );
    private static isTokenNeedToBeGenerated = true;
    private static sessionToken: google.maps.places.AutocompleteSessionToken;
    static getAutocompleteService() {
        return Places.autoCompleteService;
    }

    /**
     * Get list of prediction from a text string
     * @param text string to get the prediction from
     * @returns a precition list and status
     */
    static getPredictions(text: string): Promise<{
        predictions: google.maps.places.AutocompletePrediction[] | null;
        status: google.maps.places.PlacesServiceStatus;
    }> {
        if (this.isTokenNeedToBeGenerated) {
            Places.generateSessionToken();
        }
        return new Promise((resolve, reject) => {
            Places.autoCompleteService.getPlacePredictions(
                {
                    input: text,
                    language: "fr",
                    componentRestrictions: { country: "fr" },
                    sessionToken: Places.sessionToken,
                },
                (
                    predictions:
                        | google.maps.places.AutocompletePrediction[]
                        | null,
                    status: google.maps.places.PlacesServiceStatus
                ) => {
                    resolve({ predictions, status });
                }
            );
        });
    }
    static getPlaceDetails(placeId: string): Promise<{
        details: google.maps.places.PlaceResult | null;
        status: google.maps.places.PlacesServiceStatus;
    }> {
        return new Promise((resolve) => {
            Places.placesDetailsService.getDetails(
                { placeId, sessionToken: Places.sessionToken },
                (
                    details: google.maps.places.PlaceResult | null,
                    status: google.maps.places.PlacesServiceStatus
                ) => {
                    resolve({ details, status });
                    Places.isTokenNeedToBeGenerated = true;
                }
            );
        });
    }
    static generateSessionToken(): any {
        Places.sessionToken = new google.maps.places.AutocompleteSessionToken();
        Places.isTokenNeedToBeGenerated = false;
    }
}
