import Button from "@mui/material/Button/Button";
import Menu from "@mui/material/Menu/Menu";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import I18n from "../../../config/i18n/I18n";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ListAltIcon from "@mui/icons-material/ListAlt";
import classes from "./MenuDropdows.module.scss";
type Props = {
    isConnected: boolean;
    navigate: (location: string) => void;
    logOut: () => void;
};
const MenuDropdown = ({ isConnected, navigate, logOut }: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    /**
     * Listener for menu click events
     * @param location
     */
    const menuClickListener = (location: string) => {
        navigate(location);
        handleClose();
    };
    return (
        <>
            <Button color="secondary" onClick={handleClick}>
                <MenuIcon />
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PopoverClasses={{ paper: classes.PopoverContainer }}
                classes={{ list: classes.PopoverListContainer }}
            >
                {!isConnected ? (
                    <MenuItem
                        color="secondary"
                        onClick={() => menuClickListener("/rdv")}
                    >
                        <span className={classes.ListItemContainer}>
                            {I18n.appointment}&nbsp;&nbsp;
                            <InsertInvitationOutlinedIcon />
                        </span>
                    </MenuItem>
                ) : null}
                {!isConnected ? (
                    <MenuItem
                        color="secondary"
                        onClick={() => menuClickListener("/professional")}
                    >
                        <span className={classes.ListItemContainer}>
                            {I18n.professional}&nbsp;&nbsp;
                            <WorkOutlineOutlinedIcon />
                        </span>
                    </MenuItem>
                ) : null}
                {isConnected ? (
                    <MenuItem
                        color="secondary"
                        onClick={() => menuClickListener("/dashboard")}
                    >
                        <span className={classes.ListItemContainer}>
                            {I18n.dashboard}&nbsp;&nbsp;
                            <DashboardIcon />
                        </span>
                    </MenuItem>
                ) : null}
                {isConnected ? (
                    <MenuItem
                        color="secondary"
                        onClick={() => menuClickListener("/dashboard/pending")}
                    >
                        <span className={classes.ListItemContainer}>
                            {I18n.pendingList}&nbsp;&nbsp;
                            <ListAltIcon />
                        </span>
                    </MenuItem>
                ) : null}
                {isConnected ? (
                    <MenuItem
                        color="secondary"
                        onClick={() => menuClickListener("/dashboard/profile")}
                    >
                        <span className={classes.ListItemContainer}>
                            {I18n.profile}&nbsp;&nbsp;
                            <AccountCircleIcon />
                        </span>
                    </MenuItem>
                ) : null}
                <MenuItem
                    color="secondary"
                    onClick={() => menuClickListener("/about")}
                >
                    <span className={classes.ListItemContainer}>
                        {I18n.aboutUs}&nbsp;&nbsp;
                        <InfoOutlinedIcon />
                    </span>
                </MenuItem>
                {isConnected ? (
                    <MenuItem color="secondary" onClick={logOut}>
                        <span className={classes.ListItemContainer}>
                            {I18n.logOut}&nbsp;&nbsp;
                            <ExitToAppIcon />
                        </span>
                    </MenuItem>
                ) : null}
            </Menu>
        </>
    );
};

export default MenuDropdown;
