import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import I18n from "../../../config/i18n/I18n";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import Typography from "@mui/material/Typography/Typography";
import AppointmentDto from "../../../ws/appointment/AppointmentDto";
import moment from "moment";
import { Divider } from "@mui/material";
import classes from "./RdvDetailsDialog.module.scss";
type Props = {
    appointment: AppointmentDto;
    open: boolean;
    handleClose: () => void;
};
const RdvDetailsDialog = (props: Props) => {
    return (
        <div>
            <Dialog open={props.open} onClose={props.handleClose}>
                <DialogTitle id="alert-dialog-title">
                    {I18n.appointmentDetailsDialogTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <List>
                            <ListItem>
                                <Typography
                                    component="span"
                                    variant="h6"
                                    color="textPrimary"
                                    className={classes.ListItemTitle}
                                >
                                    {I18n.fullName} :
                                </Typography>
                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="textPrimary"
                                >
                                    {props.appointment.patient.fullName}
                                </Typography>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <Typography
                                    component="span"
                                    variant="h6"
                                    color="textPrimary"
                                    className={classes.ListItemTitle}
                                >
                                    {I18n.birthDate} :
                                </Typography>

                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="textPrimary"
                                >
                                    {String(
                                        props.appointment.patient.birthDate
                                    )}
                                </Typography>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <Typography
                                    component="span"
                                    variant="h6"
                                    color="textPrimary"
                                    className={classes.ListItemTitle}
                                >
                                    {I18n.civility} :
                                </Typography>

                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="textPrimary"
                                >
                                    {I18n.getTranslation(
                                        String(
                                            props.appointment.patient.civility
                                        )
                                    )}
                                </Typography>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <Typography
                                    component="span"
                                    variant="h6"
                                    color="textPrimary"
                                    className={classes.ListItemTitle}
                                >
                                    {I18n.mobile} :
                                </Typography>

                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="textPrimary"
                                >
                                    {props.appointment.patient.mobile}
                                </Typography>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <Typography
                                    component="span"
                                    variant="h6"
                                    color="textPrimary"
                                    className={classes.ListItemTitle}
                                >
                                    {I18n.appointmentDate} :
                                </Typography>

                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="textPrimary"
                                >
                                    {moment(
                                        props.appointment.appointmentDate
                                    ).format("DD-MM-YYYY HH:mm")}
                                </Typography>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <Typography
                                    component="span"
                                    variant="h6"
                                    color="textPrimary"
                                    className={classes.ListItemTitle}
                                >
                                    {I18n.careType} :
                                </Typography>

                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="textPrimary"
                                >
                                    {props.appointment.careType.name}
                                </Typography>
                            </ListItem>
                            <Divider />
                        </List>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={props.handleClose}
                        color="primary"
                        autoFocus
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default RdvDetailsDialog;
