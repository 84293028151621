import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import I18n from "../../../../config/i18n/I18n";
import PasswordModal from "../../../ui/passwordModal/PasswordModal";
import classes from "./ProfessionalProfile.module.scss";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ProfessionalWebService from "../../../../ws/professional/Professional.ws";
import LocalStorageUtil from "../../../../utils/localStorageUtil";
import DataLoading from "../../../ui/dataLoading/DataLoading";
import Professional from "../../../../model/Professional.model";
import Civility, { civilityOf } from "../../../../model/Civility.enum";
import { AlertDialog } from "../../../ui";

type Props = {};
const ProfessionalProfile: React.FC<Props> = (props: Props) => {
    const [professional, setProfessional] = useState<Professional>({
        numAdeli: "",
        firstName: "",
        lastName: "",
        civility: Civility.Mr,
        phone: "",
        officeNumber: "",
        type: { id: 0, name: "" },
        address: "",
        zipCode: "",
        city: "",
        email: "",
        lat: "",
        lng: "",
    });
    /**
     * Is password update or profile update
     */
    const [isPasswordUpdate, setIsPasswordUpdate] = useState<boolean>(false);
    /**
     * Is password modal open
     */
    const [isPasswordModalOpen, openPasswordModal] = useState<boolean>(false);
    /**
     * Open password modal
     */
    const openModal = () => {
        openPasswordModal(true);
        setIsPasswordUpdate(true);
    };
    const [openNetworkAlert, setOpenNetworkAlert] = useState<boolean>(false);
    /**
     * Close alert handler
     */
    const closeNetworkAlertHandler = () => {
        setOpenNetworkAlert(false);
    };
    const [progress, setProgress] = useState<boolean>(false);
    useEffect(() => {
        const userId = LocalStorageUtil.getUserId();
        ProfessionalWebService.getProfessional(String(userId)).then(
            (resp) => {
                setProgress(false);
                setProfessional(resp.data);
            },
            (error) => {
                setOpenNetworkAlert(true);
            }
        );
    }, []);
    /**
     * Close password dialog
     */
    const closePasswordDialog = () => {
        openPasswordModal(false);
    };
    /**
     * Update professional profile
     */
    const onConfirmProfileUpdate = () => {
        ProfessionalWebService.updateProfessionalProfile(professional).then(
            (resp) => {
                setProfessional(resp.data);
            }
        );
    };
    /**
     * On click update profile button
     */
    const onClickUpdateProfile = () => {
        setIsPasswordUpdate(false);
        openPasswordModal(true);
    };
    /**
     * Display Network alert modal
     */
    const displayNetworkAlert = () => {
        setOpenNetworkAlert(true);
    };
    if (progress) {
        return <DataLoading message={I18n.loadingDataMessage} />;
    } else {
        return (
            <div>
                <AlertDialog
                    open={openNetworkAlert}
                    onClick={closeNetworkAlertHandler}
                    title={I18n.networkErrorTitle}
                    content={I18n.networkErrorContent}
                />
                <PasswordModal
                    displayNetworkAlert={displayNetworkAlert}
                    onConfirm={onConfirmProfileUpdate}
                    isPasswordUpdate={isPasswordUpdate}
                    closeDialog={closePasswordDialog}
                    open={isPasswordModalOpen}
                />
                <Paper variant="outlined" style={{ padding: 10 }}>
                    <Card>
                        <CardHeader
                            avatar={<Avatar arial-label="logo">L</Avatar>}
                            title={
                                professional.firstName && professional.lastName
                                    ? professional.firstName +
                                      " " +
                                      professional.lastName
                                    : ""
                            }
                            classes={{
                                root: classes.CardHeaderContainer,
                                action: classes.CardHeaderActionContainer,
                            }}
                            // subheader={professional.type.name}
                            action={
                                <Button
                                    classes={{
                                        root: classes.CardHeaderActionContainer,
                                    }}
                                    color="primary"
                                    variant="outlined"
                                    onClick={openModal}
                                >
                                    {I18n.changePassword}&nbsp;&nbsp;
                                    <LockOpenIcon />
                                </Button>
                            }
                        ></CardHeader>
                        <CardContent>
                            <div
                                className={classes.ProfileCardContnentContainer}
                            >
                                <TextField
                                    placeholder={I18n.numAdeli}
                                    name="numAdeli"
                                    variant="outlined"
                                    value={professional.numAdeli}
                                    label={I18n.numAdeli}
                                    disabled
                                />
                                <TextField
                                    placeholder={I18n.firstname}
                                    label={I18n.firstname}
                                    name="firstName"
                                    variant="outlined"
                                    value={professional.firstName}
                                    onChange={(event) =>
                                        setProfessional({
                                            ...professional,
                                            firstName: event.target.value,
                                        })
                                    }
                                />
                                <TextField
                                    placeholder={I18n.lastname}
                                    label={I18n.lastname}
                                    name="lastName"
                                    variant="outlined"
                                    value={professional.lastName}
                                    onChange={(event) =>
                                        setProfessional({
                                            ...professional,
                                            lastName: event.target.value,
                                        })
                                    }
                                />
                                <Autocomplete
                                    options={["MR", "MRS"]}
                                    getOptionLabel={(option) =>
                                        I18n.getTranslation(option.toString())
                                    }
                                    onChange={(element, value) =>
                                        setProfessional({
                                            ...professional,
                                            civility: civilityOf(String(value)),
                                        })
                                    }
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            label={I18n.civility}
                                            variant="outlined"
                                            name="civility"
                                        />
                                    )}
                                />
                                <TextField
                                    placeholder={I18n.mobile}
                                    label={I18n.mobile}
                                    onChange={(event) =>
                                        setProfessional({
                                            ...professional,
                                            phone: event.target.value,
                                        })
                                    }
                                    name="phone"
                                    type="tel"
                                    variant="outlined"
                                    value={professional.phone}
                                />
                                <TextField
                                    placeholder={I18n.officeNumber}
                                    label={I18n.officeNumber}
                                    onChange={(event) =>
                                        setProfessional({
                                            ...professional,
                                            officeNumber: event.target.value,
                                        })
                                    }
                                    name="officeNumber"
                                    type="tel"
                                    variant="outlined"
                                    value={professional.officeNumber}
                                />
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    color="primary"
                                    onClick={onClickUpdateProfile}
                                >
                                    {I18n.update}
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </Paper>
            </div>
        );
    }
};
export default ProfessionalProfile;
