import React, { useEffect, useState } from "react";
import { TextField, Button, Stack } from "@mui/material";
import Autocomplete from "@mui/lab/Autocomplete";
import I18n from "../../../config/i18n/I18n";
import Professional from "../../../model/Professional.model";
import { useForm } from "react-hook-form";
import { Alert } from "@mui/lab";
import ProfessionalTypeWebService from "../../../ws/professional/ProfessionalType.ws";
import ProfessionalType from "../../../model/ProfessionalType.model";
import AlertDialog from "../../ui/alertDialog/AlertDialog";
import { phonePattern } from "../../../config/regExpPatterns";
import environment from "../../../config/environment";
import * as _ from "lodash";
import PlacesAutoComplete from "../../ui/placesAutoComplete/PlacesAutoComplete";
import { Position } from "../../../model/Appointment.model";
type Props = {
    nextStep: VoidFunction;
    updateProfessional: (key: string, value: any) => void;
    updateProfessionalPositionState: (position: Position) => void;
    professional: Professional;
};
const ProfessionalInformationStep: React.FC<Props> = (props: Props) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Professional>();
    const onSubmit = (data: any) => props.nextStep();
    const [openNetworkAlert, setOpenNetworkAlert] = useState<boolean>(false);
    /**
     * Close alert handler
     */
    const closeNetworkAlertHandler = () => {
        setOpenNetworkAlert(false);
    };
    const [professionalTypes, setProfessionalTypes] = useState(
        [] as Array<ProfessionalType>
    );
    useEffect(() => {
        ProfessionalTypeWebService.getProfessionalTypes().then(
            (resp) => {
                setProfessionalTypes(resp.data);
            },
            (error) => {
                setOpenNetworkAlert(true);
            }
        );
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <AlertDialog
                open={openNetworkAlert}
                onClick={closeNetworkAlertHandler}
                title={I18n.networkErrorTitle}
                content={I18n.networkErrorContent}
            />
            <Stack gap={1}>
                <PlacesAutoComplete
                    errors={errors}
                    register={register}
                    placeSelected={props.updateProfessionalPositionState}
                />
                <TextField
                    {...register("numAdeli", {
                        required: true,
                        onChange: (event) => {
                            props.updateProfessional(
                                "numAdeli",
                                event.target.value
                            );
                        },
                    })}
                    error={errors.numAdeli !== undefined}
                    placeholder={I18n.numAdeli}
                    name="numAdeli"
                    variant="outlined"
                />
                <TextField
                    {...register("firstName", {
                        required: true,
                        onChange: (event) =>
                            props.updateProfessional(
                                "firstName",
                                event.target.value
                            ),
                    })}
                    error={errors.firstName !== undefined}
                    placeholder={I18n.firstname}
                    variant="outlined"
                />
                <TextField
                    {...register("lastName", {
                        required: true,
                        onChange: (event) =>
                            props.updateProfessional(
                                "lastName",
                                event.target.value
                            ),
                    })}
                    placeholder={I18n.lastname}
                    error={errors.lastName !== undefined}
                    variant="outlined"
                />
                <Autocomplete
                    options={["MR", "MRS"]}
                    getOptionLabel={(option) =>
                        I18n.getTranslation(option.toString())
                    }
                    onChange={(element, value) =>
                        props.updateProfessional("civility", value)
                    }
                    renderInput={(params: any) => (
                        <TextField
                            {...register("civility", { required: true })}
                            error={errors.civility !== undefined}
                            {...params}
                            label={I18n.civility}
                            variant="outlined"
                            name="civility"
                        />
                    )}
                />
                <Autocomplete
                    options={professionalTypes}
                    onChange={(element, value) =>
                        props.updateProfessional("type", value)
                    }
                    getOptionLabel={(option) =>
                        option && option.name ? String(option.name) : ""
                    }
                    renderInput={(params: any) => (
                        <TextField
                            {...register("type", { required: true })}
                            error={errors.type !== undefined}
                            {...params}
                            label={I18n.professionalType}
                            variant="outlined"
                        />
                    )}
                />
                <TextField
                    error={errors.phone !== undefined}
                    label={I18n.mobile}
                    placeholder={I18n.mobilePlaceholder}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...register(
                        "phone",
                        environment.production
                            ? {
                                  required: true,
                                  maxLength: 10,
                                  pattern: {
                                      value: phonePattern,
                                      message: "invalid phone number",
                                  },
                                  onChange: (event) =>
                                      props.updateProfessional(
                                          "phone",
                                          event.target.value
                                      ),
                              }
                            : {
                                  required: true,
                                  onChange: (event) =>
                                      props.updateProfessional(
                                          "phone",
                                          event.target.value
                                      ),
                              }
                    )}
                />
                <TextField
                    error={errors.officeNumber !== undefined}
                    label={I18n.officeNumber}
                    placeholder={I18n.officeNumberPlaceholder}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...register(
                        "officeNumber",
                        environment.production
                            ? {
                                  required: true,
                                  maxLength: 10,
                                  pattern: {
                                      value: phonePattern,
                                      message: "invalid phone number",
                                  },
                                  onChange: (event) =>
                                      props.updateProfessional(
                                          "officeNumber",
                                          event.target.value
                                      ),
                              }
                            : {
                                  required: true,
                                  onChange: (event) =>
                                      props.updateProfessional(
                                          "officeNumber",
                                          event.target.value
                                      ),
                              }
                    )}
                />

                <Button type="submit" variant="outlined" color="primary">
                    {I18n.next}
                </Button>
                {(errors.firstName ||
                    errors.lastName ||
                    errors.numAdeli ||
                    errors.civility ||
                    errors.phone ||
                    errors.officeNumber ||
                    errors.type) && (
                    <Alert severity="error">{I18n.requiredProperties}</Alert>
                )}
            </Stack>
        </form>
    );
};
export default ProfessionalInformationStep;
