import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import I18n from "../../../config/i18n/I18n";
import User from "../../../model/User.model";
import _ from "lodash";
import Alert from "@mui/lab/Alert";
import { useForm } from "react-hook-form";
import { Stack } from "@mui/material";

type professionalSignUpPropsType = {
    nextStep: VoidFunction;
    signUp: () => void;
    updateProfessional: (key: string, value: any) => void;
};
const ProfessionalSignUp: React.FC<professionalSignUpPropsType> = (
    props: professionalSignUpPropsType
) => {
    const [confirmPassword, setConfirmPassword] = useState({
        password: "",
        rPassword: "",
    });
    const [validPassword, setValidPassword] = useState(true);
    /**
     * Validate user Password before signUp
     */
    const validateUser = () => {
        if (_.isEqual(confirmPassword.password, confirmPassword.rPassword)) {
            props.signUp();
        } else {
            setValidPassword(false);
        }
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<User>();
    /**
     * Submit signUp form
     */
    const onSubmit = () => {
        validateUser();
    };
    const onPasswordChange = (value: string) => {
        props.updateProfessional("password", value);
        setConfirmPassword({ ...confirmPassword, password: value });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={1}>
                <TextField
                    type="email"
                    error={errors.email !== undefined}
                    {...register("email", { required: true })}
                    label={I18n.email}
                    variant="outlined"
                    onChange={(event) =>
                        props.updateProfessional("email", event.target.value)
                    }
                />
                <TextField
                    type="password"
                    error={errors.password !== undefined}
                    {...register("password", { required: true })}
                    label={I18n.password}
                    variant="outlined"
                    onChange={(event) => onPasswordChange(event.target.value)}
                />
                <TextField
                    type="password"
                    label={I18n.repeatPassword}
                    variant="outlined"
                    onChange={(event) =>
                        setConfirmPassword({
                            ...confirmPassword,
                            rPassword: event.target.value,
                        })
                    }
                />
                <Button type="submit" variant="outlined" color="primary">
                    {I18n.signUp}
                </Button>
                <br />
                {(errors.email || errors.password) && (
                    <Alert severity="error">{I18n.requiredProperties}</Alert>
                )}
                {validPassword ? null : (
                    <Alert severity="error">{I18n.verifyPassword}</Alert>
                )}
            </Stack>
        </form>
    );
};
export default ProfessionalSignUp;
