enum Civility{
Mr,Mrs
};
export function civilityOf(value:string):Civility{
    console.log(value)
    switch (value.toUpperCase()){
        case 'MR':return Civility.Mr;
        case 'MRS':return Civility.Mrs;
        default : return Civility.Mr;
    }
}
export function civilityOfNumber(value:number):Civility{
    switch (value){
        case 0:return Civility.Mr;
        case 1:return Civility.Mrs;
        default : return Civility.Mr;
    }
}
export default Civility;