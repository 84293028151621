import React from 'react'
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
type Props = {
    activeStep: number,
    steps: Array<string>
}
const SoinsdomStepper = (props: Props) => {
    return (
        <>
            <Stepper activeStep={props.activeStep}>
                {props.steps.map(item => {
                    return (<Step completed={false} key={item}>
                        <StepLabel>
                            <Typography variant="caption">{item}</Typography>
                        </StepLabel>
                    </Step>)
                })}
            </Stepper>
        </>
    )
}

export default SoinsdomStepper
