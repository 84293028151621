import LanguageContract from "./LanguageContract";
import * as _ from "lodash";
class French implements LanguageContract {
    getTranslation(value: string): string {
        const entries = Object.entries(this);
        const item = _.find(entries, (entry) => {
            return _.isEqual(entry[0], value);
        });
        if (item) {
            return item[1];
        } else {
            return value;
        }
    }

    public appName = "SOINSDOM";
    public appointment = "RDV";
    public professional = "PROFESSIONNELS";
    public aboutUs = "A PROPOS DE NOUS";
    public getAppointment = "PRENDRE RDV";
    public search = "RECHERCHER";
    public findAProfessional = "Trouver un professionnel de santé";
    public copyright = "Copyright © 2020 Hz. All rights reserved";
    public privacy = "Politique de confidentialité";
    public contactUs = "Nous contacter";
    public email = "Email";
    public firstname = "Prénom";
    public lastname = "Nom";
    public object = "Objet";
    public message = "Message";
    public send = "Envoyer";
    public position = "Déterminer votre position";
    public careRequest = "Demande de soins";
    public myInformation = "Mes informations";
    public address = "Adresse";
    public zipCode = "Code postal";
    public city = "Ville";
    public next = "Suivant";
    public previous = "Précédent";
    public mobile = "Numéro mobile";
    public mobilePlaceholder = "Numéro mobile 06 ou 07 XXXXXXXX";
    public birthDate = "Date de naissance";
    public civility = "Civilité";
    public appointmentDate = "Date de rendez-vous";
    public careType = "Type des soins";
    public professionalAccess = "Accés professionnel";
    public createProfessionalAccount = "Créer votre compte professionnel";
    public numAdeli = "Num Adeli";
    public officeNumber = "Numéro de cabinet";
    public officeNumberPlaceholder = "Numéro de cabinet 06 ou 07 XXXXXXXX";
    public signUp = "S'inscrire";
    public signIn = "Se connecter";
    public password = "Mot de passe";
    public repeatPassword = "Confirmer votre mot de passe";
    public login = "Login";
    public requiredProperties = "Veuillez remplir tous les champs requis";
    public agreeToConditions = " J'accepte les conditions";
    public agreeToConditionsAlert =
        "Veuillez accepter les conditions d'utilisation";
    public verifyPassword = "Les mots de passe ne correspondent pas";
    public professionalType = "Type de professionnel";
    public dashboard = "Dashboard";
    public profile = "Profil";
    public update = "Modifier";
    public changePassword = "Changer mon mot de passe";
    public MR = "M";
    public MRS = "Mme";
    public rdv = "Prendre un rendez-vous";
    public professionalPage = "S'inscrire en tant qu'un professionnel de santé";
    public alreadySignedUp = "J'ai un compte";
    public requiredFields = "Veuillez remplir tous les champs";
    public invalidCredentials = "les informations saisies sont incorrectes";
    public rdvConfirmationTitle = "Confirmation";
    public rdvConfirmationContent =
        "Votre rendez vous est confirmé, nous allons l'envoyer aux professionels de santé !";
    public professionalAccountCreatedTitle = "Confirmation";
    public professionalAccountCreatedContent =
        "Votre compte a été créé avec succès ! Veuillez se connecter afin d'accéder au dashboard";
    public networkErrorTitle = "Erreur !";
    public networkErrorContent =
        "Une erreur s'est produite lors du traitement de votre demande ! veuillez ressayer plus tard ";
    public loadingDataMessage = "Chargement des données...";
    public id = "id";
    public fullName = "Nom et Prenom";
    public date = "date";
    public accept = "Accepter";
    public reject = "Refuser";
    public actions = "Actions";
    public details = "Détails";
    public logOut = "Se déconnecter";
    public appointmentTreatedTitle = "Rendez-vous traité";
    public appointmentTreatedContent = "Votre réponse sera traitée";
    public pendingList = "rdvs";
    public otp = "code";
    public invalidOtp = "Le code saisi est invalid";
    public smsSent = "Veuillez entrer le code envoyé à votre numéro : ";
    public smsSentPhoneNumber = "Veuillez entrer votre numéro mobile : ";
    public emailExists = "L'email saisi est existe";
    public emailExistsMessage = "Veuillez saisir un autre e-mail svp !";
    public disabledAccount = "Votre compte n'est pas encore activé";
    public disabledAccountMessage =
        "Veulliez activé votre compte par un valide numéro mobile ! ";
    public appointmentDetailsDialogTitle = "Les détails du RDV";
}
export default new French();
