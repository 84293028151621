import React from "react";
import PageTitle from "../../features/ui/pageTitle/PageTitle";
import RdvQueue from "../../features/professional/dashboard/rdvQueue/RdvQueue";
import { Route } from "react-router-dom";
import ProfessionalProfile from "../../features/professional/dashboard/professionalProfile/ProfessionalProfile";
import SoinsdomCalendar from "../../features/professional/dashboard/soinsdomCalendar/SoinsdomCalendar";
import classes from "./Dashboard.module.scss";
import { Box } from "@mui/material";

type Props = {};
const Dashboard = (props: Props) => {
    return (
        <Box
            className={classes.DashboardContainer}
            sx={{ padding: { xs: 2, md: 10 } }}
        >
            <PageTitle title="Dashboard" />
            <Route path="/dashboard/" exact>
                <SoinsdomCalendar />
            </Route>
            <Route path="/dashboard/pending" exact>
                <RdvQueue />
            </Route>
            <Route path="/dashboard/profile">
                <ProfessionalProfile />
            </Route>
        </Box>
    );
};

export default Dashboard;
