import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Alert from "@mui/lab/Alert";
import React, { useState } from "react";
import I18n from "../../../config/i18n/I18n";
import classes from "./PasswordModal.module.scss";
import * as _ from "lodash";
import Dialog from "@mui/material/Dialog/Dialog";
import AuthWebService from "../../../ws/auth/Auth.ws";
import LocalStorageUtil from "../../../utils/localStorageUtil";
type Props = {
    open: boolean;
    closeDialog: () => void;
    onConfirm: () => void;
    isPasswordUpdate: boolean;
    displayNetworkAlert: () => void;
};

const PasswordModal: React.FC<Props> = (props: Props) => {
    const [user, setUser] = useState({
        password: "",
        rPassword: "",
    });
    const [validPassword, setValidPassword] = useState(true);
    /**
     * Validate user Password before signUp
     */
    const validatePassword = () => {
        if (_.isEqual(user.password, user.rPassword)) {
            if (props.isPasswordUpdate) {
                AuthWebService.updatePassword({
                    id: LocalStorageUtil.getUserId(),
                    password: user.password,
                    email: "",
                }).then(
                    (resp) => {
                        props.closeDialog();
                    },
                    (error) => {
                        props.closeDialog();
                        props.displayNetworkAlert();
                    }
                );
            } else {
                AuthWebService.isPasswordValid({
                    id: LocalStorageUtil.getUserId(),
                    password: user.password,
                    email: "",
                }).then(
                    (resp) => {
                        props.onConfirm();
                        props.closeDialog();
                    },
                    (error) => {
                        props.closeDialog();
                        props.displayNetworkAlert();
                    }
                );
            }
        } else {
            setValidPassword(false);
        }
    };

    return (
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            onClose={props.closeDialog}
        >
            <DialogTitle id="alert-dialog-title">{I18n.password}</DialogTitle>

            <form>
                <DialogContent
                    classes={{ root: classes.dialogContentContainer }}
                >
                    <TextField
                        type="password"
                        label={I18n.password}
                        variant="outlined"
                        onChange={(event) =>
                            setUser({ ...user, password: event.target.value })
                        }
                    />
                    <TextField
                        type="password"
                        label={I18n.repeatPassword}
                        variant="outlined"
                        onChange={(event) =>
                            setUser({ ...user, rPassword: event.target.value })
                        }
                    />
                    <br />
                    {validPassword ? null : (
                        <Alert severity="error">{I18n.verifyPassword}</Alert>
                    )}
                </DialogContent>
                <DialogActions
                    classes={{ root: classes.dialogActionContainer }}
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={validatePassword}
                    >
                        {I18n.update}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
export default PasswordModal;
