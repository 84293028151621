import React from "react";
import Landing from "../pages/landing/Landing";
import Contact from "../pages/contact/Contact";
import About from "../pages/about/About";
import Appointment from "../pages/appointment/Appointment";
import Privacy from "../pages/privacy/Privacy";
import Conditions from "../pages/conditions/Conditions";
import Professional from "../pages/professional/Professional";
import Dashboard from "../pages/dashboard/Dashboard";
import SignIn from "../pages/signIn/SignIn";
import { Route, Switch } from "react-router-dom";

type routerPropsType = {};
const router: React.FC<routerPropsType> = (props: routerPropsType) => {
    return (
        <Switch>
            <Route path="/dashboard">
                <Dashboard />
            </Route>
            <Route path="/rdv">
                <Appointment />
            </Route>
            <Route path="/professional">
                <Professional />
            </Route>
            <Route path="/about">
                <About />
            </Route>
            <Route path="/contact">
                <Contact />
            </Route>
            <Route path="/privacy">
                <Privacy />
            </Route>
            <Route path="/conditions">
                <Conditions />
            </Route>
            <Route path="/sign-in">
                <SignIn />
            </Route>
            <Route path="/" exact>
                <Landing />
            </Route>
        </Switch>
    );
};
export default router;
