import React, { useEffect, useReducer, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, Event, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import ProfessionalWebService from "../../../../ws/professional/Professional.ws";
import LocalStorageUtil from "../../../../utils/localStorageUtil";
import AppointmentDto from "../../../../ws/appointment/AppointmentDto";
import "../../../../config/i18n/MomentLocale";
import RdvDetailsDialog from "../../../appointment/rdvDetailsDialog/RdvDetailsDialog";
import Civility from "../../../../model/Civility.enum";
const localizer = momentLocalizer(moment);

const messages = {
    // new
    allDay: "Toute la journée",
    previous: "Précédent",
    next: "Suivant",
    today: "Aujourd'hui",
    month: "Mois",
    week: "Semaine",
    day: "jour",
    agenda: "Agenda",
    date: "Date",
    time: "Temps",
    event: "événement",
    noEventsInRange: "Il n'y a aucun événement dans cette plage.",
    work_week: "Semaine de travail",
    yesterday: "Hier",
    tomorrow: "Demain",
};

const SoinsdomCalendar = () => {
    const [modalAppointment, setModalAppointment] = useState<AppointmentDto>({
        id: "",
        address: "",
        lng: "",
        lat: "",
        careType: { id: 0, name: "" },
        appointmentDate: new Date(),
        patient: {
            fullName: "",
            email: "",
            mobile: "",
            birthDate: new Date(),
            civility: Civility.Mr,
        },
    });
    const [openAppointmentModal, setOpenAppointmentModal] = useReducer(
        (state: boolean) => !state,
        false
    );
    const [appointments, setAppointments] = useState<Event[]>([]);
    useEffect(() => {
        ProfessionalWebService.getAppointment(
            String(LocalStorageUtil.getUserId())
        ).then((resp) => {
            setAppointments(mapAppointmentToEvent(resp.data));
        });
    }, []);
    /**
     *
     * @param appointments appointments
     */
    const mapAppointmentToEvent = (
        appointments: Array<AppointmentDto>
    ): Event[] => {
        const events: Event[] = appointments.map<Event>((item) => ({
            allDay: false,
            title: item.patient.fullName,
            start: moment(item.appointmentDate).toDate(),
            end: moment(item.appointmentDate).toDate(),
            resource: item,
        }));
        return events;
    };
    /**
     *
     * @param element Display appointmentDetails
     */
    const displayAppointmentDetails = (element: any) => {
        setModalAppointment(element.resource);
        setOpenAppointmentModal();
    };
    return (
        <div>
            <RdvDetailsDialog
                appointment={modalAppointment}
                open={openAppointmentModal}
                handleClose={setOpenAppointmentModal}
            />
            <Calendar
                localizer={localizer}
                events={appointments}
                startAccessor="start"
                endAccessor="end"
                messages={messages}
                style={{ height: 500 }}
                onSelectEvent={displayAppointmentDetails}
            />
        </div>
    );
};
export default SoinsdomCalendar;
